import React, { useEffect, useState } from 'react';
import "./organization-info.scss"
import { Controller, useForm } from 'react-hook-form';
import FieldError from '../../../../shared/fields-errors/field-error';
import axios from 'axios';
import { ADD_UPDATE_ORGANIZATION_BY_MASJID_ID, FIND_ORG_SHORT_NAME, GET_ORGANIZATION_BY_ID } from '../../../../constants/endpoints';
import { ENVIRONMENT_CONSTANT } from '../../../../constants/env.constant';
import { getSetOrgInfo, getUserInfo } from '../../../../utils/user-info';
import useUploadFile from '../../../../hooks/upload-file.hook';
import Loader from '../../../../shared/loader/loader';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

function OrganizationInfoComponent(props) {

    // const [imageSrc, setImageSrc] = useState("");
    const LogoBaseUrl = ENVIRONMENT_CONSTANT.LOGO_BASE_URL;
    const masjidInfo = getUserInfo();
    const [requestLoader, setRequestLoader] = useState(false);
    const {file, fileError, fileProperties,fileUploadLoader, checkIsValidImage} = useUploadFile(null, masjidInfo);
    const [organization, setOrganization] = useState({});
    
    // const uploadLogo = (type) => {
    //     if(type === 'Remove'){
    //         setImageSrc('');
    //     }else{
    //         const url = document.getElementById('masjidLogo')?.value || "";
    //         setImageSrc(url);    
    //     }    
    // }

    useEffect(() => {
        setImageProps(fileProperties);
        // if(fileProperties?.image_name){
        //     setValue('logo',masjidInfo?.masjid_id+'_'+fileProperties);
        // }else{
        //     setValue('logo',"");
        // }
        
        // addUpdateOrganization(getValues());
    }, [fileProperties])

    const getOrganization = () => {
        axios.get(GET_ORGANIZATION_BY_ID)
        .then((res) => {
            if(res?.data?.response?.id){
                res.data.response.country = 
                res?.data?.response?.country?.toLowerCase() === 'usa' ? 'US' : res?.data?.response?.country;
                setOrganization(res?.data?.response);
                reset(res?.data?.response);
            }
        }).catch((error) => {

        })
    }

    useEffect(() => {
        getOrganization()
    },[])

    const methods = useForm();
    const { register,control,setValue, handleSubmit,
        setError,clearErrors,
        unregister, reset, formState: { errors }, getValues,watch } = methods;
    const fields = watch();

    const addUpdateOrganization = (value) => {
        // console.log(value)
        value.organization_short_name = value?.organization_short_name?.replaceAll(" ","")
        setRequestLoader(true);
        axios.post(ADD_UPDATE_ORGANIZATION_BY_MASJID_ID,value)
        .then((res) => {
            setRequestLoader(false);
            if(res?.data?.status){
                // localStorage.setItem(ENVIRONMENT_CONSTANT.ORGANIZATION_LOGO, value.logo);
                reset({...getValues(), ...{id:res?.data?.response?.identifiers[0]?.id}});
                setOrganization({...organization, ...{organization_short_name: value?.organization_short_name}});
                alert('Record saved successfully.');
                updateLocalStorage(value);
                window.location.reload();
            }else{
                alert(res?.data?.response ? res?.data?.response : 'Unable to save organization info.');
            }
        }).catch((error) => {
            setRequestLoader(false);
            alert('Unable to save organization info.')
        })
    }

    const updateLocalStorage = (values) => {
        const info = {
            organization_short_name: values?.organization_short_name,
            organization_name: values?.organization_name,
            logo: values?.image_location,
            organization_email: values?.organization_email,
            currency: values?.currency
        }

        getSetOrgInfo(info);
        //changing a logo if user has updated it 
        const logoElement = document.getElementById('main-logo');
        if(logoElement){
            logoElement.src = LogoBaseUrl + values?.image_location
        }
    }

    const setImageProps = (response) => {
        setValue('logo', response?.logo ? response?.logo : '');
        setValue('image_location', response?.image_location ? response?.image_location : '');
        setValue('image_alt_name', response?.image_alt_name ? response?.image_alt_name : '');
    }

    //api call to find if short name is already exists or not

    const findByShortName = async (short_name) => {
        try {
          setRequestLoader(true);
          clearErrors("organization_short_name");
          const response = await axios.get(FIND_ORG_SHORT_NAME + short_name);
          setRequestLoader(false);
          if (response?.data?.status) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
            setRequestLoader(false);
        }
        // .then((res) => {
        //     setRequestLoader(false);
        //     if(res?.data?.status){
        //         setError('organization_short_name', {
        //             type:'shortNameExits',
        //             message:'Short name already exists, try a different name.'
        //         },true);
        //     }
        // }).catch((error) => {
        //     setRequestLoader(false);
        // });
    }

    return (
        <div>
            <div className='col-xl-12'>
                <div>
                    <h5 className='font-weight-semibold text-black'>Organization Information</h5>
                </div>
            </div>
            <div className='card org-info'>
                <form onSubmit={handleSubmit(addUpdateOrganization)}>
                <div className='card-body'>
                    {/* general settings start */}
                    <div className='row mb-3'>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                            <div className='col-12 border-bottom mb-3'>
                                <h6 className='text-bold'>General Settings</h6>
                            </div>
                            <div className='col-12'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Organization Name
                                </label>
                                    <input 
                                    {...register("organization_name",{
                                        required: true
                                    })}
                                    placeholder="Organization Name" type="text"
                                        className="form-control-alternative form-control org-input-field" />

                                        {errors?.organization_name &&
                                            errors?.organization_name?.type === 'required' ?
                                            <FieldError message={'This field is required'} /> : ''}
                                </div>
                            </div>
                            <div className='row'>
                            <div className='col-md-8 col-12'>
                                <div className="form-group"><label className="form-control-label text-black">
                                <span className='text-danger'>*</span> Organization Short Name <p className='f-12-small text-warning'>(Short name can consists of only 15 characters)</p>
                                </label>
                                    <input 
                                    onKeyDown={(e) => {
                                        if (e.code === 'Space') e.preventDefault()
                                    }}
                                    maxLength={15}
                                    {...register("organization_short_name",{
                                        validate:{
                                            shortNameExits: async (value) => {
                                                if(value?.toLowerCase() !== organization?.organization_short_name?.toLowerCase()){
                                                    const response = await findByShortName(value)
                                                    if(response){
                                                        return 'Short name already exists, try a different name.'
                                                    }else{
                                                        return true
                                                    }
                                                }
                                            },
                                        },
                                        required:{
                                            value: true,
                                            message: "This field is required"
                                        },
                                        maxLength: {
                                            value: 15,
                                            message:'Maximum 15 characters is allowed'
                                        }
                                    })}
                                    placeholder="Organization Short Name" type="text"
                                        className="form-control-alternative form-control org-input-field" />

                                        {errors?.organization_short_name &&
                                            errors?.organization_short_name ?
                                            <FieldError message={errors?.organization_short_name?.message} /> : ''}
                                </div>
                            </div>
                                <div className='col-md-4 col-12 mt-md-5'>
                                    <label className="form-control-label d-flex align-items-center">
                                        <input {...register("enable_ach")}
                                         className='me-1' type="checkbox" /> ACH Enabled
                                    </label>
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                {/* <label className="form-control-label text-black d-flex justify-content-between" >
                                    <span>Logo</span> */}
                                    {/* {
                                        imageSrc ?
                                        <div className='logo-actions'>
                                        <span className='me-2' onClick={openFileExplorer}>Upload new logo</span>
                                        <span onClick={removeLogo}>Remove logo</span>
                                    </div> : '' 
                                    } */}
                                    
                                {/* </label> */}
                                {/* <div className='d-flex justify-content-between'>
                                    <input
                                    {...register("logo",{
                                        pattern: {
                                            value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                                            message: "Enter a valid url."
                                          }
                                    })}
                                     id='masjidLogo' placeholder="Paste image url" type="text"
                                        className="form-control-alternative form-control org-input-field w-85" />
                                    <button onClick={(() => {uploadLogo((imageSrc ? 'Remove' : 'Preview'))})} type="button"
                                        className={`btn btn-ma-primary btn-md mb-2`}>
                                        {imageSrc ? 'Remove' : 'Preview'}
                                    </button>

                                    
                                </div>
                                {errors?.logo ?
                                            <FieldError message={errors?.logo?.message} /> : ''} */}
                                
                                <div className='upload-org-logo text-center position-relative'>
                                    {fileUploadLoader ? <Loader /> : ''}
                                    {/* {!imageSrc ? <a className='cursor-pointer' onClick={openFileExplorer}>Click to Upload a file</a> : ''} */}
                                    {
                                        !fileProperties?.image_alt_name && !organization?.logo ?
                                            <div className='d-flex flex-column align-item-center justify-content-center'>
                                                <h4>Upload an Image</h4>
                                                <i className="fa-regular fa-image f-45"></i>
                                                {/* <p>Image Preview</p> */}
                                                    <div className='mt-2'>
                                                    <button onClick={(e) => {
                                                        const uploader = document.getElementById('fileUploader')
                                                        if(uploader){
                                                            uploader.click();
                                                        }
                                                    }} type="button"
                                                        className={`btn btn-ma-primary btn-md mb-2`}>
                                                        Upload
                                                    </button>
                                                    
                                                    </div>
                                                    {
                                                        fileError ? 
                                                        <FieldError message={fileError} /> : <span className='f-16 text-ma-primary'>{fileProperties?.image_alt_name}</span>
                                                    }
                                                    
                                            </div> : 
                                            <div className='d-flex flex-column align-items-center'>
                                                <img 
                                                src={`${LogoBaseUrl}${fileProperties?.image_location ? (fileProperties?.image_location) : organization?.image_location}`} className='img-fluid organization-logo mb-2' />
                                                
                                                 {
                                                        fileError ? 
                                                        <FieldError message={fileError} /> : <span className='f-16 text-ma-primary'>{
                                                            fileProperties?.image_alt_name ? fileProperties?.image_alt_name : organization?.image_alt_name}</span>
                                                    }
                                            <button
                                            onClick={(e) => {
                                                const uploader = document.getElementById('fileUploader')
                                                if(uploader){
                                                    uploader.click();
                                                }
                                            }}
                                             type='button' className={`mt-2 btn btn-ma-primary btn-md mb-2`}>Change Logo</button>
                                            </div>
                                    }
                                    
                                    <input onChange={(e) => checkIsValidImage(e, true)} id='fileUploader' type='file' className='d-none' 
                                                    accept='.png, .jpeg, .jpg'/>
                                    {/* <input id='uploadFile' accept="image/jpeg, image/png, image/jpg" onChange={handleFileChange} className='d-none' type='file' /> */}
                                    {/* <div onClick={openFileExplorer} className={`${imageSrc ? 'upload-org-logo__update' : 'd-none'}`}>
                                        <a>Update Logo</a>
                                    </div> */}
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className='col-12 border-bottom mb-3'>
                                <h6 className='text-bold'>Organization Detail</h6>
                            </div>
                            <div className='col-12'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Tax ID / EIN / ABN
                                </label>
                                    <input 
                                    {...register('tax_id',{
                                        required: true
                                    })}
                                    placeholder="Tax ID / EIN / ABN" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                        {errors?.tax_id &&
                                            errors?.tax_id?.type === 'required' ?
                                            <FieldError message={'This field is required'} /> : ''}
                                </div>
                            </div>
                            {/* <div className='col-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                    Country
                                </label>
                                    <input
                                    {...register("country")} 
                                    placeholder="Country" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                </div>
                            </div> */}
                            <div className='col-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Address
                                </label>
                                    <input 
                                    {...register('address',{
                                        required: true
                                    })}
                                    placeholder="Address" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                        {errors?.address &&
                                            errors?.address?.type === 'required' ?
                                            <FieldError message={'This field is required'} /> : ''}
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Country
                                </label>
                                        {/* <CountryDropdown classes='form-control'
                                        {...register("country",{
                                            validate:{
                                                required: value => {
                                                    if(!value){
                                                        return "This field is required"
                                                    }
                                                    return true
                                                }
                                            }
                                        })} 
                                        /> */}
                                        <Controller
                                            name="country"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "This field is required"
                                                }
                                            }}
                                            render={({ field: { name, onChange, value } }) => (
                                                <CountryDropdown 
                                                whitelist={['US','CA', 'GB']}
                                                valueType='short' classes='form-control'
                                                    defaultOptionLabel="Select Country"
                                                    name={name}
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                            control={control}
                                        />
                                        {errors?.country ?
                                            <FieldError message={errors?.country?.message} /> : ''}
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Currency
                                </label>
                                <select 
                                {...register("currency",{
                                    required:{
                                        value: true,
                                        message: "This field is required"
                                    }
                                })} 
                                className="form-control">
                                <option value={""}>Select Currency</option>
                                <option value="cad">CAD</option>
                                <option value="gbp">GBP</option>
                                <option value="usd">USD</option>
                                </select>
                                        {errors?.currency ?
                                            <FieldError message={errors?.currency?.message} /> : ''}
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> State
                                </label>
                                    {/* <input
                                    {...register("state",{
                                        validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            }
                                        }
                                    })}
                                     placeholder="State" type="text"
                                        className="form-control-alternative form-control org-input-field" /> */}
                                        
                                        <Controller
                                            name="state"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "This field is required"
                                                }
                                            }}
                                            render={({ field: { name, onChange, value } }) => (
                                                <RegionDropdown
                                                name={name}
                                        countryValueType="short"
                                        country={fields?.country} 
                                        classes='form-control'
                                        onChange={onChange}
                                        value={value}
                                        />
                                            )}
                                            control={control}
                                        />
                                        {errors?.state ?
                                            <FieldError message={errors?.state?.message} /> : ''}
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> City
                                </label>
                                    <input
                                    {...register("city",{
                                        validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            }
                                        }
                                    })} 
                                    placeholder="City" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                        {errors?.city ?
                                            <FieldError message={errors?.city?.message} /> : ''}
                                </div>
                            </div>
                            
                            <div className='col-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Zip Code
                                </label>
                                    <input
                                    {...register("postal_code",{
                                        validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            }
                                        }
                                    })}
                                     placeholder="Postal Code" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                                {errors?.postal_code ?
                                            <FieldError message={errors?.postal_code?.message} /> : ''}
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Website
                                </label>
                                    <input
                                    {...register("website",{
                                        pattern: {
                                            value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                                            message: "Enter a valid url."
                                          },
                                          validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            }
                                          }
                                    })} 
                                    placeholder="Website" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                 {errors?.website ?
                                            <FieldError message={errors?.website?.message} /> : ''}
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                            <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Email
                                </label>
                                    <input
                                    {...register("organization_email",{
                                        validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            }
                                        }
                                    })} 
                                     placeholder="Email" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                          {errors?.organization_email ?
                                            <FieldError message={errors?.organization_email?.message} /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* general settings start */}

                    
                    {/* custom stripe processing fee */}
                    <div className='mb-3 row'>
                        <div className='col-12 border-bottom mb-3'>
                        <h6 className='text-bold'>Adjust Stripe Processing Fee</h6>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Stripe Flat Fee
                                </label>
                                    <input
                                    {...register("stripe_flat_fee",{
                                        // valueAsNumber:true,
                                        pattern: {
                                            value: /^\d+(\.\d{1,2})?$/,
                                            message: "Invalid currency format for flat fee"
                                        },
                                        validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            },
                                        }
                                    })} 
                                    placeholder="Stripe Flat Fee" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                            {errors?.stripe_flat_fee ?
                                            <FieldError message={errors?.stripe_flat_fee?.message} /> : ''}
                                </div>
                                <p className='f-12-small text-warning'>(Default stripe flat rate is $0.30, you can change it according to your limit)</p>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Stripe Percentage Fee
                                </label>
                                    <input
                                    // (parseFloat(2.9) / 100).toFixed(3)
                                    {...register("stripe_percentage_fee",{
                                        // valueAsNumber:true,
                                        pattern: {
                                            value: /^\d+(\.\d{1,2})?$/,
                                            message: "Invalid currency format for percentage fee"
                                        },
                                        min: {
                                            value: 0,
                                            message: 'Percentage fee must be greater than or equal to 0',
                                        },
                                          max: {
                                            value: 100,
                                            message: 'Percentage fee must be less than or equal to 100',
                                        },
                                        validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            },
                                        }
                                    })} 
                                    placeholder="Stripe Percentage Fee" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                            {errors?.stripe_percentage_fee ?
                                            <FieldError message={errors?.stripe_percentage_fee?.message} /> : ''}
                                </div>
                                <p className='f-12-small text-warning'>(Default stripe percentage is 2.9%, you can change it according to your limit)</p>
                            </div> 
                        </div>

                    <div className="row">
                    <div className='col-12 border-bottom mb-3'>
                                <h6 className='text-bold'>Organization Contact</h6>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Contact Person (Full Name)
                                </label>
                                    <input
                                    {...register("contact_person_name",{
                                        validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            }
                                        }
                                    })} 
                                    placeholder="Contact Person (Full Name)" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                            {errors?.contact_person_name ?
                                            <FieldError message={errors?.contact_person_name?.message} /> : ''}
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Contact Email
                                </label>
                                    <input
                                    {...register("contact_email",{
                                        validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            }
                                        }
                                    })} 
                                     placeholder="Contact Email" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                          {errors?.contact_email ?
                                            <FieldError message={errors?.contact_email?.message} /> : ''}
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                <span className='text-danger'>*</span> Contact Phone
                                </label>
                                    <input
                                    {...register("contact_phone",{
                                        validate:{
                                            required: value => {
                                                if(!value){
                                                    return "This field is required"
                                                }
                                                return true
                                            }
                                        }
                                    })} 
                                     placeholder="Contact Phone" type="text"
                                        className="form-control-alternative form-control org-input-field" />
                                                 {errors?.contact_phone ?
                                            <FieldError message={errors?.contact_phone?.message} /> : ''}
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2'>
                                <div className="form-group"><label className="form-control-label text-black " >
                                    About Your Organization
                                </label>
                                <textarea
                                {...register("about_your_organization",{
                                    // validate:{
                                    //         required: value => {
                                    //             if(!value){
                                    //                 return "This field is required"
                                    //             }
                                    //             return true
                                    //         }
                                    //     }
                                })}  
                                placeholder="About Your Organization" cols={5} rows={1} className="form-control-alternative form-control org-input-field"></textarea>
                                 {errors?.about_your_organization ?
                                            <FieldError message={errors?.about_your_organization?.message} /> : ''}
                                </div>
                            </div>
                    </div>

                    <div className='col-12 text-right mt-3'>
                        {/* ${updateCampaignLoader ? 'button--loading' : ''} */}
                        {/* <button type="button" className="btn btn-outline-secondary mx-3">Cancel</button> */}
                        <button type="submit"
                        disabled={requestLoader}
                            className={`btn btn-ma-primary btn-md position-relative ${requestLoader ? 'button--loading' : ''}`}>
                            Save
                        </button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    );
}

export default OrganizationInfoComponent;