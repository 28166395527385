import React from 'react';
import { formattedNumber } from '../../utils/formatted-number';
import { useOutletContext } from 'react-router-dom';

function DonationStats(props) {
    const stats = props?.data;
    //getting route context
    const {currencySign} = useOutletContext();

    return (
        <div className='row mt-3'>
        <div className='col-xl-4 col-lg-4 col-md-4 text-center mt-3 mt-xl-0 mt-lg-0 mt-md-0 sm-mt-0'>
            <div className='card'>
                <p className='mt-3'>Gross Donation</p>
                <span className='mb-3 text-bold f-22 text-black'>{currencySign}{formattedNumber(stats?.gross_donations)}</span>
            </div>
        </div>
        <div className='col-xl-4 col-lg-4 col-md-4 text-center mt-3 mt-xl-0 mt-lg-0 mt-md-0 sm-mt-0'>
            <div className='card'>
                <p className='mt-3'>Average Donation</p>
                <span className='mb-3 text-bold f-22 text-black'>{currencySign}{formattedNumber(stats?.average_donation)}</span>
            </div>
        </div>
        <div className='col-xl-4 col-lg-4 col-md-4 text-center mt-3 mt-xl-0 mt-lg-0 mt-md-0 sm-mt-0'>
            <div className='card'>
                <p className='mt-3'>Donations</p>
                <span className='mb-3 text-bold f-22 text-black'>{stats?.total_donations}</span>
            </div>
        </div>
    </div>
    );
}

export default DonationStats;