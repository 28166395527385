import React from 'react';
import NoDataComponent from '../../shared/no-data/no-data';
import getFormattedDate, { dateTimeFormat } from '../../utils/date-format';
import { Button } from 'react-bootstrap';
import { Link, useOutletContext } from 'react-router-dom';
import Loader from '../loader/loader';
import CardType from '../../shared/card-type/card-type';
import { PAYMENT_STATUS } from '../../constants/payment-status.constant';
import moment from 'moment';

function DonationTable(props) {

    const columns = props?.columns;
    const showTitle = props?.showTitle;
    const donations = props?.data;
    //getting route context
    const {currencySign} = useOutletContext();

    return (
        <div className='row mt-3 position-relative'>
        <div className='col-xl-12'>
            <div className='card'>
                <div className="table-responsive">
                    {
                        !props?.dataLoader ? 
                        <table className="table align-items-center ">
                        <thead className='text-center prayers-table-header white'>
                            <tr>
                                {
                                    columns.map((item, index) => {
                                        return <th key={index}>{item}</th>
                                    })
                                }
                                {/* <th>ID & Date</th>
                            <th>Donor</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Donor Comments</th>
                            <th>Internal Notes</th> */}
                            </tr>
                        </thead>
                        <tbody className='prayer-table-body text-center'>
                            {
                                donations.map((item,key) => {
                                    return <tr key={key}>
                                    <td>
                                    {
                                        !props?.isSuperAdmin ? 
                                        <Link to={`/donations/detail/${item.id}/${item.campaign_id}`} 
                                    className='text-light-blue'>{item.id.split('-')[0]?.toUpperCase()}</Link> : ''
                                    }
                                    <p className='mb-0'>{
                                        item?.platform_used === 'Offline' ? 
                                        // getFormattedDate(item?.created_at)
                                        moment(item?.created_at).utc().format("MM/DD/YYYY") :
                                        // dateTimeFormat(item?.created_at)
                                        moment(item?.created_at).format("MM/DD/YYYY hh:mm")
                                    }</p>
                                    </td>
                                    {/* <td>{item?.}</td> */}
                                    <td>{item?.campaign_title}</td>
                                    {!props?.isSuperAdmin ? <td>{item?.donation_designation}</td> : ''}
                                    {!props?.isSuperAdmin ? <td>
                                    <Link to={`/donor-detail/${item?.donor_id}/${item?.first_name +' '+item?.last_name}`} className='text-light-blue'>{item?.first_name +' '+item?.last_name}</Link></td> : ''}
                                    <td>{currencySign}{(item?.amount)}</td>
                                    <td className='text-capitalize'>
                                        <span>{item?.platform_used}</span>
                                        {item?.platform_used ? <br /> : ''}
                                        {item?.donation_type}
                                        <span> / {item?.payment_medium}</span>
                                    </td>
                                    <td className='text-capitalize'>
                                        {
                                            item?.platform_used !== 'Offline' ? 
                                            <CardType card_type={item?.card_type} last_4_digits={item?.last_4_digits} /> : (item?.payment_type)
                                        }
                                        {/* <p className="mb-0">{(item?.payment_type && item?.platform_used !== 'Offline') ? `${item?.payment_type}` : ''}</p> */}
                                    </td>
                                    <td title={item?.payment_log ? item?.payment_log : 'Payment is in process'} className={
                                        `text-bold text-capitalize ${
                                            item?.payment_status === PAYMENT_STATUS.SUCCESS ? 'text-success' :
                                        item?.payment_status === PAYMENT_STATUS.FAILED ? 'text-danger' :
                                        !item?.payment_status ? 'text-warning' : ''
                                        }`
                                    }>
                                        {
                                            item?.payment_status ? item?.payment_status : 'Pending'
                                        }
                                    </td>
                                    {/* <td>{item?.donor_comments}</td> */}
                                </tr>
                                })
                            }
                           
                        </tbody>

                    </table> : ''
                    }

                    {/* no data message */}
                    {
                      
                        !props?.dataLoader && donations?.length === 0 ?
                        <NoDataComponent message={'No Donations Found'} /> : ''
                    }
                    
                </div>
            </div>

                <div className='mt-2 text-right'>
                    {
                        props?.pageIndex ? 
                        <Button
                        onClick={() => {
                            const index = props?.pageIndex - 1;
                            props?.changePage(index)
                        }}
                         className='me-2' type='button'
                        variant="primary">
                        Previous
                    </Button> : ''
                    }

                    <Button disabled={donations?.length > 0 ? false : true} onClick={() => {
                        const index = props?.pageIndex + 1;
                        props?.changePage(index)
                    }} type='button'
                        variant="primary">
                        Next
                    </Button>
                </div>
        </div>
        {
              props?.dataLoader ? 
              <Loader /> : ''
        }
    </div>
    );
}

export default DonationTable;