import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import IntervalAmount from './interval-amount';
import FieldError from '../../../../../shared/fields-errors/field-error';
import { useOutletContext } from 'react-router-dom';

function AmountForm(props) {

    const [selectedInterval, setSelectedInterval] = useState('');
    const { register, unregister, formState: { errors } } = useFormContext();
    //getting route context
    const {currencySign} = useOutletContext();

    useEffect(() => {
        if (props?.defaultIntervalList?.length > 0) {
            setSelectedInterval(props?.defaultIntervalList[0]);
            // let formArray = [];
            // for (let index = 0; index < props?.defaultIntervalList.length; index++) {
            //     let item = {
            //         type: props.defaultIntervalList[index],
            //         display_currency: false,
            //         not_allow_custom_donation: false,
            //         minimum_donation: 10,
            //         enforce_max_donation: false,
            //         max_donation: null
            //     }

            //     formArray.push(item);

            // }

            // props.amountArray.append(formArray);
        }
    }, [props?.defaultIntervalList]);

    const changeAmountForm = (interval) => {
        setSelectedInterval(interval);
        // let index = props?.amountArray.fields.findIndex(it => it.type === interval);
        // if(index !== -1){
        //     setCurrentAmountFormIndex(index);
        // }
    }

    const toggleMaxDonationField = (event) => {
        if (event?.target?.checked) {
            register(`donation_amount.max_donation`);

        } else {
            unregister(`donation_amount.max_donation`);
        }
        // donation_amount.amount_intervals[${index}].enforce_max_donation
    }

    return (
        <>
            {/* campaigns amount info start */}
            <div className='col-12 card mt-3'>
                <div className='card-body'>
                    <label className="form-control-label text-black text-bold">
                        Donation Amounts
                    </label>

                    <div className='mt-2'>
                        <div className='choose_interval_list d-flex'>
                            {
                                props?.defaultIntervalList.map((item, index) => {
                                    return <label onClick={(() => {
                                        // setSelectedInterval(item);
                                        changeAmountForm(item);
                                    })} key={index} className={selectedInterval === item ? 'active' : ''}>
                                        <strong>{item}</strong>
                                    </label>
                                })
                            }

                            {/* <label htmlFor="suggested_amounts_by_interval_weekly">
                                <strong>Weekly</strong>
                            </label>
                            <label htmlFor="suggested_amounts_by_interval_monthly">
                                <strong>Monthly</strong>
                            </label>
                            <label htmlFor="suggested_amounts_by_interval_annual">
                                <strong>Annual</strong>
                            </label> */}
                        </div>
                    </div>
                    {
                        props.amountArray.fields.map((item, index) => {
                            return selectedInterval === item.type ? <div key={item.id}>
                                <div className='form-group mt-3'>
                                    <div className='row'>
                                        <div className='col-12'>Amount({currencySign})</div>
                                        {/* <div className='col-8'>What does this amount provide?</div> */}
                                    </div>
                                    {/* interval amount nested controls start */}
                                    <IntervalAmount parentIndex={index} register={register} control={props?.control} />
                                    {/* interval amount nested controls end */}

                                    {/* {
                                        item?.amountListRef?.fields.map((row, amountIndex) => {
                                            <div key={amountIndex}
                                            className='row align-items-center mt-2'>
                                            <div className='col-4'>
                                                <input
                                                    type="text" className="form-control-alternative form-control" placeholder='20.0' />
                                            </div>
                                            <div className='col-6'>
                                                <input
                                                    type="text" className="form-control-alternative form-control" placeholder='Help' />
                                            </div>
                                            <div className='col-2'>
                                                <i onClick={(() => addRemoveAmount(index, amountIndex))} className="fa-solid fa-square-minus me-2 f-12 cursor-pointer"></i>
                                            </div>
    
                                        </div>
                                        })
                                       
                                    } */}
                                </div>

                            </div> : ''
                        })
                    }

                    
                            <hr />
                                <div className='form-group mt-3'>
                                    {/* <label className="form-control-label d-flex align-items-center">
                                        <input {...register(`donation_amount.display_currency`)} className="me-1" type="checkbox" /> Display currency
                                        code (USD, EUR, etc.)
                                    </label> */}
                                    <label className="form-control-label d-flex align-items-center mt-3">
                                        <input {...register(`donation_amount.not_allow_custom_donation`)} className="me-1" type="checkbox" /> Do not
                                        allow custom donations
                                    </label>
                                </div>

                                <hr />

                                <div className="form-group mt-3">
                                    <label className="form-control-label">
                                        <span className='text-danger text-bold'>*</span> Minimum Donation Amount ({currencySign})
                                    </label>
                                    <input {...register(`donation_amount.minimum_donation`,{
                                        value:5,
                                        validate:{
                                            required: value => {
                                                if (!value) return 'Amount is required';
                                               return true;            
                                           },
                                           min: value => {
                                                   if (value <= 0){
                                                       return `Amount cannot be less then or equal to 0`
                                                   };
                                               return true;            
                                           }
                                        }
                                    })} placeholder="Minimum Donation Amount" type="number"
                                        className="form-control-alternative form-control" />
                                         {
                                    errors?.donation_amount?.minimum_donation ?
                                        <FieldError message={
                                            errors?.donation_amount?.minimum_donation?.type === 'required' ?
                                            errors?.donation_amount?.minimum_donation?.message : 
                                            errors?.donation_amount?.minimum_donation?.type === 'min' ? 
                                            `Amount cannot be less then or equal to 0` : ''
                                        } /> : ''
                                }
                                </div>

                                <div className='form-group mt-3'>
                                    <label className="form-control-label d-flex align-items-center mt-3">
                                        <input {...register(`donation_amount.enforce_max_donation`, {
                                            onChange: ((e) => {
                                                toggleMaxDonationField(e);
                                            })
                                        })}
                                            className="me-1" type="checkbox" /> Enforce max donation amount
                                    </label>
                                    {
                                        props?.watchAllFields?.donation_amount?.enforce_max_donation === true ?
                                            <input {...register(`donation_amount.max_donation`,{
                                                value:0,
                                                validate:{
                                                    required: value => {
                                                        if (!value) return 'Amount is required';
                                                       return true;            
                                                   },
                                                   min: value => {
                                                           if (value <= 0){
                                                               return `Amount cannot be less then or equal to 0`
                                                           };
                                                       return true;            
                                                   }
                                                }
                                            })} placeholder="Max Donation Amount" type="number"
                                                className="form-control-alternative form-control" />
                                                
                                            : ''
                                    }
                                           {
                                    errors?.donation_amount?.max_donation ?
                                        <FieldError message={
                                            errors?.donation_amount?.max_donation?.type === 'required' ?
                                            errors?.donation_amount?.max_donation?.message : 
                                            errors?.donation_amount?.max_donation?.type === 'min' ? 
                                            `Amount cannot be less then or equal to 0` : ''
                                        } /> : ''
                                }

                                </div>

                </div>
            </div>
            {/* campaigns amount info end */}

            {/* processing fee start */}
            <div className='col-12 card mt-3'>
                <div className='card-body'>
                    <label className="form-control-label text-black text-bold">
                        Processing Fee
                    </label>

                    <div className='form-group mt-3'>
                        <label className="form-control-label d-flex align-items-center">
                            <input
                                {...register(`donation_amount.ask_donors_cover_fee`)}
                                className="me-1" type="checkbox" /> Ask donors to cover fee
                        </label>
                    </div>
                    <div className='form-group mt-2'>
                        <label className="form-control-label d-flex align-items-center">
                            <input
                                {...register(`donation_amount.ask_donors_cover_fee_default`)}
                                className="me-1" type="checkbox" /> Ask donors to cover fees by default
                        </label>
                    </div>

                </div>
            </div>
            {/* processing fee end */}

            {/* donate labels start */}
            <div className='col-12 card mt-3'>
                <div className='card-body'>
                    <label className="form-control-label text-black text-bold">
                        "Donate" Labels
                    </label>

                    <div className='form-group mt-3'>
                        <label className="form-control-label d-flex align-items-center">
                            <input
                                {...register(`donation_amount.change_label_to_pay`)}
                                className="me-1" type="checkbox" /> Change "Donate" labels to "Pay"
                        </label>
                    </div>

                </div>
            </div>
            {/* donate labels end */}

            {/* compliance and disclaimer start  */}
            <div className='col-12 card mt-3'>
                <div className='card-body'>
                    <label className="form-control-label text-black text-bold">
                        Compliance and Disclaimer
                    </label>

                    <div className="form-group mt-3">
                        <textarea
                            {...register(`donation_amount.compliance`)}
                            rows={2} placeholder="Enter your tax status, if applicable" className="form-control-alternative form-control" ></textarea>
                    </div>

                </div>
            </div>
            {/* compliance and disclaimer end  */}
        </>
    );
}

export default AmountForm;