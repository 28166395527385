import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import FieldError from '../../../shared/fields-errors/field-error';
import "../donation-widget/donation-widget.scss";

import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
import { GET_CAMPAIGN_BY_LINK, GET_FORM_SUBMISSION, GET_PAYMENT_INTENT, GET_RAISED_DONATIONS, SAVE_PAYMENT_INFO } from '../../../constants/endpoints';
import { string, z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import PaymentRequestForm from '../../../shared/payment-request-form/payment-request.form';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../donation-widget/payment-form';
import DonationDesignationTemplate from '../donation-widget/donation_designation_tempate';
import PledgeUserInfo from './pledge-user-info';
import calculateCCAfterFee from '../../../utils/stripe-payment-fee.util';
import { getTextColorByBG } from '../../../utils/utils';
import getFormattedDateTime from '../../../utils/date-time-format';
import { ENVIRONMENT_CONSTANT } from '../../../constants/env.constant';
import DonorCommentsTemplate from '../donation-widget/donor-comments-template';
import ChargeFixedNumberOfTime from '../donation-widget/charged-fixed-number-of-times';
import getFormattedDate from '../../../utils/date-format';
import PledgeConfirmation from '../../../shared/pledge-confirmation/pledge-confirmation';
import { CURRENCY_CODE_SIGN_MAPPING } from '../../../constants/currency-code-sign.constant';

// const stripePromise = loadStripe("pk_test_51LlE3vEiJqwdwXrrwn3PRBuB1J8DqjXlFVPz6g78PtgV32CWFdXLWObtXrwXTKF9at6U6tRvpMoC4fc1OmKvgmu600Pe7zcEBX");
const publishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
// const stripePromise = loadStripe(publishKey);
// const clientSecret = 'pi_3MT3OBEiJqwdwXrr1qzbkVOU_secret_BMWulPp252EQOZ391nZTfM53Y'
function DonationPledge(props) {
    const [stripeButtonElementLoader, setStripeButtonElementLoader] = useState(true);
    const logoBaseUrl = ENVIRONMENT_CONSTANT.LOGO_BASE_URL;
    const appearance = {
        theme: 'stripe',
    };

    const pathName = window.location.pathname;
    const requestFormRef = useRef(null);
    const donorInfoRef = useRef(null);
    const [loader, setLoader] = useState(true);
    const [selectedAmount, setSelectedAmount] = new useState('');
    const [customAmount, setCustomAmount] = new useState('');
    const [campaignDetail, setCampaignDetail] = new useState({});
    const [donationTypes, setDonationTypes] = new useState([]);

    const [stripePromise, setStripePromise] = useState(null);
    const [selectedDonationType, setSelectedDonationType] = new useState('');
    const [showError, setShowError] = new useState(false);


    //new props for amount selection start
    const [currentDisplayingAmount, setCurrentDisplayingAmount] = useState({});
    //new props for amount selection end

    //   const options = {
    //     clientSecret,
    //     appearance,
    //   };

    const params = useParams();
    //getting query params
    const [queryParams, setQueryParams] = useSearchParams();
    const formID = queryParams.get('form_id');
    const submissionID = queryParams.get('submission_id');
    const [formStep, setFormStep] = useState('amount-selection');
    // var campaignID = params?.id;
    var link = params?.link;
    var short_code = params?.short_code;

    const methods = useForm({
        defaultValues: {
            donation_designation: null
        }
    });
    // const { register, trigger, handleSubmit, reset, clearErrors, watch, formState: { isValid, errors }, getValues } = useForm({
    //     // resolver: zodResolver(infoSchema)
    // });
    const { register, trigger, handleSubmit, setValue, reset, clearErrors, 
        unregister,
        watch, formState: { isValid, errors }, getValues } = methods;
    // const errors = {}

    //getting campaign detail by campaign id
    const getCampaignDetail = async () => {
        axios.get((`${GET_CAMPAIGN_BY_LINK}${link}/${short_code}`))
            .then(async (res) => {
                if (res?.data?.status) {
                    //setting up global variables for stripe processing fee
                    window.stripe_flat_fee = parseFloat(res?.data?.response?.stripe_flat_fee);
                    window.stripe_percentage_fee = (parseFloat(res?.data?.response?.stripe_percentage_fee) / 100).toFixed(3);
                    
                    setCampaignDetail(res?.data?.response);
                    // if(res?.data?.response?.primary_color){
                    //     res.data.response.primary_color += '   !important';
                    // }

                    let list = [];
                    if (res?.data?.response?.one_time_interval) list.push({ name: 'One-Time' });
                    if (res?.data?.response?.daily_interval) list.push({ name: 'Daily' });
                    if (res?.data?.response?.weekly_interval) list.push({ name: 'Weekly' });
                    if (res?.data?.response?.weekly_jumuah_only) list.push({ name: 'Every-Jumuah' });
                    if (res?.data?.response?.biweekly_interval) list.push({ name: 'Bi-weekly' });
                    if (res?.data?.response?.monthly_interval) list.push({ name: 'Monthly' });
                    if (res?.data?.response?.quarterly_interval) list.push({ name: 'Quarterly' });
                    if (res?.data?.response?.annaully_interval) list.push({ name: 'Annually' });

                    setDonationTypes(list);

                    //loading stripe start
                    if (res?.data?.response?.stripe_account_id) {
                        const stripePromise = await loadStripe(publishKey, {
                            stripeAccount: res?.data?.response?.stripe_account_id
                        });

                        setStripePromise(stripePromise)
                    }
                    //loading stripe end
                    if (parseFloat(res?.data?.response?.goal_amount)) getRaisedDonation(res?.data?.response);

                } else {
                    alert(res?.data?.message);
                    window.location.href = `${window.location.origin}/${short_code}`;
                }
            }).catch((error) => {
                alert('Unable to get information regarding donation');
            })
    }

    //getting raised donations from db
    const [raisedDonations, setRaisedDonations] = useState({})
    const getRaisedDonation = async (campaign) => {
        axios.post(GET_RAISED_DONATIONS, campaign)
            .then((res) => {
                if (res?.data?.success) {
                    setRaisedDonations(res?.data?.response)
                }
            }).catch((error) => {

            })
    }
    useEffect(() => {
        if(short_code){
            getCampaignDetail();
        }
        
    }, [short_code]);

    const [descriptiveAmountView, setDescriptiveAmountView] = useState(true);
    useEffect(() => {
        let currentInterval;
        // if (campaignDetail?.donation_amount?.amount_intervals?.length > 0) {
        //     //setting up current displaying amount start
        //     currentInterval = campaignDetail?.donation_amount?.amount_intervals[0];
        //     //setting up current displaying amount end
        // }else{
        for (let index = 0; index < donationTypes.length; index++) {
            const currentIntervalIndex = campaignDetail?.donation_amount?.amount_intervals.findIndex(it => it?.type === donationTypes[index]?.name);
            if (currentIntervalIndex === -1) {
                const interval = {
                    type: donationTypes[index]?.name,
                    amount_list: [
                        { amount: 25 },
                        { amount: 50 },
                        { amount: 100 }
                    ]
                }

                campaignDetail?.donation_amount?.amount_intervals.push(interval);
            }
        }
        // }

        if (campaignDetail?.default_interval) {
            currentInterval = campaignDetail?.donation_amount?.amount_intervals?.find(it => it.type?.toLowerCase() === campaignDetail?.default_interval?.toLowerCase());
        } else {
            const type = donationTypes[0]?.name;
            currentInterval = campaignDetail?.donation_amount?.amount_intervals.find(it => it.type === type);
        }

        //checking if any interval has description then set description view true
        const isDescriptiveView = campaignDetail?.donation_amount?.amount_intervals?.some(it => {
            const isTrue = it?.amount_list?.some(s => s.description?.trim());
            if(isTrue){
                setDescriptiveAmountView(true);
                return true
            }else{
                setDescriptiveAmountView(false);
            }
        })
        if (currentInterval) {
            setSelectedDonationType(currentInterval?.type);
            // if(currentInterval?.amount_list?.length > 0){
            //     const isDescription = currentInterval?.amount_list.some(it => it?.description?.trim());
            //     setDescriptiveAmountView(isDescription);
            // }
            setCurrentDisplayingAmount(currentInterval);
        }

        for (let index = 0; index < campaignDetail?.donation_amount?.amount_intervals.length; index++) {
            campaignDetail?.donation_amount?.amount_intervals[index]?.amount_list.sort((a, b) => {
                return a.amount - b.amount
            });
        }
        //setting up default amounts if user not provided amounts for intervals


    }, [campaignDetail])

    const selectAmount = (value) => {
        // setSelectedAmount(value === selectedAmount ? '' : value);
        reset({
            selected_amount: (value === fieldsWatcher?.selected_amount ? '' : value),
            custom_amount: null,
            recurring_option_value: fieldsWatcher?.recurring_option_value,
            is_charge_number_of_times: fieldsWatcher?.is_charge_number_of_times
        })
        // reset('selected_amount',(value === fieldsWatcher?.selected_amount ? '' : value));
        // reset('custom_amount',null);
        setCustomAmount('');
    }

    const selectCustomAmount = (event) => {
        // let res = event?.target?.value ? setCustomAmount(event?.target?.value) : '';
        setCustomAmount(event?.target?.value);
    }
    const selectDonationType = (value, isJotform) => {
        setSelectedDonationType(value);
        let amountByType = campaignDetail?.donation_amount?.amount_intervals?.find(it => it?.type?.toLowerCase() === value?.toLowerCase());
        // setValue('selected_amount',null);
        // setValue('custom_amount',null);
        if(!isJotform){
            reset({
                custom_amount: null,
                selected_amount: null
            })
        }
        
        if (amountByType) setCurrentDisplayingAmount(amountByType);
    }

    const returnSelectedAmount = () => {
        //old flow start
        // let amount = !selectedAmount ? !customAmount ? 0 : customAmount : selectedAmount;
        // return amount;

        return parseFloat(
            (!getValues()?.selected_amount || getValues()?.selected_amount === 'custom_amount') ? getValues()?.custom_amount :
                getValues()?.selected_amount)
        // fieldsWatcher?.selected_amount === 'custom_amount' ? fieldsWatcher?.custom_amount : fieldsWatcher?.selected_amount);
    }
    const chooseAmountValidation = async (isPayLater) => {
        if (!selectedDonationType) {
            alert('Select at-least one interval');
            return;
        }

        let isAmountValid = true;
        if(!campaignDetail?.donation_amount?.not_allow_custom_donation){
            isAmountValid = await trigger('custom_amount');
        }else{
            if(!fieldsWatcher?.selected_amount){
                isAmountValid = false;
            }else{
                isAmountValid = true;
            }
        }
        const isDonationDesignation = await trigger('donation_designation');
        const isDonorComments = await trigger('donor_comments');
        let chargeNumberOfTimes;
        
        //checking if recurring option value is set by user or not
        if(campaignDetail?.recurring_option === 'allow_user_to_select_number_of_times' && fieldsWatcher?.is_charge_number_of_times){
            chargeNumberOfTimes = await trigger('recurring_option_value');
        }else{
            chargeNumberOfTimes = true;
        }
        if (isAmountValid && isDonationDesignation && isDonorComments && chargeNumberOfTimes) {
            const amount = parseFloat(
                (!fieldsWatcher?.selected_amount || fieldsWatcher?.selected_amount === 'custom_amount') ? fieldsWatcher?.custom_amount :
                    fieldsWatcher?.selected_amount)
            // !fieldsWatcher?.selected_amount ? fieldsWatcher?.custom_amount :
            // fieldsWatcher?.selected_amount === 'custom_amount' ? fieldsWatcher?.custom_amount : fieldsWatcher?.selected_amount);

            //old flow calling payment intent start
            // getPaymentIntent(amount);
            //old flow calling payment intent end

            //new implementation for payment, setting up payment options for payment element start
            const finalAmount =
                ((campaignDetail?.donation_amount?.ask_donors_cover_fee_default
                    ? Math.round(parseFloat(calculateCCAfterFee((amount)) * 100)) : (amount * 100)))
            setAmount(finalAmount);
            // setOptions();
            //new implementation for payment, setting up payment options for payment element end

            if (campaignDetail?.google_apple_pay && isGoogleApplePay && !isPayLater) {
                setFormStep('request_button');
            } else {
                donorInfoRef?.current?.setPayLater(isPayLater);
                setFormStep('info')
            }
            // setShowError(false);
            clearErrors();
        } else {
            trigger();
        }

        //old logic start

        // let amount = !selectedAmount ? !customAmount ? 0 : customAmount : selectedAmount;
        // if (!amount || !selectedDonationType) {
        //     setShowError(true);

        // } else {
        //     getPaymentIntent(amount);
        //     setFormStep('request_button');
        //     setShowError(false);
        // }
        //old logic end
    }
    
    const getAmount = () => {
        return !selectedAmount ? !customAmount ? 0 : customAmount : selectedAmount;
    }

    //second form props start
    const [isShowComments, setIsShowComments] = useState(false);

    const handleShowComments = () => {
        setIsShowComments(!isShowComments);
    };
    const infoSchema = z.object(
        {
            "first_name": string().min(1, 'First name is required.'),
            "last_name": string().min(1, 'Last name is required.'),
            "email": string().email('Enter a valid email'),
            "donor_comments": string().optional()
        }
    );

    const fieldsWatcher = watch();
    const donorInfo = () => {
        console.log(getValues())
        if (isValid) {
            if(!requestFormRef?.current?.removeFeeForACH && campaignDetail?.donation_amount?.ask_donors_cover_fee_default){
                setValue('cover_processing_fee', true);
                const amount = returnSelectedAmount();
               requestFormRef?.current?.setFinalAmount(parseFloat(calculateCCAfterFee(amount)));
            }else{
                setValue('cover_processing_fee', false);
                requestFormRef?.current?.setFinalAmount(returnSelectedAmount());
            }
            setFormStep('payment');
        } else {
            trigger();
        }

        // onClick={(() => setFormStep('payment'))}
    }
    //second form props end

    //payment success screen start
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    // const onPaymentSuccessful = (message, formData, paymentMethodID) => {
    //     saveDonationInfo(message,formData, paymentMethodID);
    // }
    //payment success screen end

    //save payment info in db start
    const [savePaymentInfoLoader, setSavePaymentInfoLoader] = useState(false);
    // const saveDonationInfo = (message, formValues = null, paymentMethodID) => {
    //     setSavePaymentInfoLoader(true);
    //     // console.log(selectedDonationType)
    //     // console.log(getAmount())
    //     // console.log(getValues());
    //     // console.log(document.getElementById('txtAddress')?.value);
    //     let info = {
    //         campaign_id:campaignID,
    //         donation_type:selectedDonationType,
    //         // amount:parseFloat(getAmount()),
    //         amount:parseFloat(returnSelectedAmount()),
    //         masjid_id: campaignDetail?.masjid_id,
    //         stripe_account_id: campaignDetail?.stripe_account_id,
    //         // postal_address: document.getElementById('txtAddress')?.value ? document.getElementById('txtAddress')?.value : ''
    //     }

    //     let formData;
    //     if (formValues) {
    //         formData = { ...getValues(),...formValues, ...info };
    //     } else {
    //         formData = { ...getValues(), ...info };
    //         formData.payment_method_id = paymentMethodID;
    //     }

    //     if(formData?.cover_processing_fee){
    //         const finalAmount = parseFloat(calculateCCAfterFee(formData.amount));
    //         formData.stripe_processing_fee = (parseFloat(finalAmount) - formData.amount).toFixed(2);
    //         formData.amount = finalAmount;
    //     }else{
    //         formData.stripe_processing_fee = 0;
    //     }

    //     let endPoint = SAVE_PAYMENT_INFO;
    //     axios.post(endPoint,formData)
    //     .then((res) => {
    //         setSavePaymentInfoLoader(false);
    //         if(res?.data?.status){
    //             if(campaignDetail?.is_redirection_url && campaignDetail?.redirection_url){
    //                 const redirectURL = 
    //                 (campaignDetail?.redirection_url?.includes('http') || campaignDetail?.redirection_url?.includes('https')) ?
    //                 campaignDetail?.redirection_url : 'https://'+campaignDetail?.redirection_url;
    //                 window.open(redirectURL, '_blank');
    //                 window.close();

    //             }else{
    //                 setPaymentSuccess(true);
    //             }

    //         }else{
    //             alert(res?.data?.response);    
    //         }
    //     }).catch((error) => {
    //         setSavePaymentInfoLoader(false);
    //         if(error?.message){
    //             alert(error?.message?.toString());
    //         }else{
    //             alert('Unable to save payment info');
    //         }

    //     })
    // }
    //save payment info in db start

    //new flow for payment start
    const onPaymentSuccess = (message) => {
        if (campaignDetail?.is_redirection_url && campaignDetail?.redirection_url) {
            const redirectURL =
                (campaignDetail?.redirection_url?.includes('http') || campaignDetail?.redirection_url?.includes('https')) ?
                    campaignDetail?.redirection_url : 'https://' + campaignDetail?.redirection_url;
                    window.open(redirectURL, '_self');
        } else {
            setFormStep('confirmation')
            // setPaymentSuccess(true);
        }
    }
    //send payload to children component start

    const returnDonationPayload = (formData) => {
        const donationPayload = {
            campaign_id: campaignDetail?.donation_amount?.campaign_id,
            donation_type: selectedDonationType,
            campaign_title: campaignDetail?.campaign_title,
            // amount:parseFloat(getAmount()),
            amount: parseFloat(returnSelectedAmount()),
            masjid_id: campaignDetail?.masjid_id,
            stripe_account_id: campaignDetail?.stripe_account_id,
            return_url: window.location.origin,
            ...getValues(),
            ...(formData ? formData : {})
        }

        //setting up jotform submission id if donation is coming from jotform
        if(submissionID){
            donationPayload.jotform_submission_id = submissionID;
        }
        if(campaignDetail?.recurring_option !== 'allow_user_to_select_number_of_times' && selectedDonationType !== 'One-Time'){
            donationPayload.recurring_option_value = campaignDetail?.recurring_option_value;
        }
        //replacing amount based on stripe fee
        if (donationPayload?.cover_processing_fee) {
            const finalAmount = parseFloat(calculateCCAfterFee(donationPayload.amount));
            donationPayload.stripe_processing_fee = (parseFloat(finalAmount) - donationPayload.amount).toFixed(2);
            donationPayload.amount = finalAmount;
        } else {
            donationPayload.stripe_processing_fee = 0;
        }

        setFinalAmount(donationPayload.amount);
        //after replacing amount and setting up stripe fee returning payload to child
        console.log(donationPayload)
        return donationPayload;
    }
    //send payload to children component end
    //new flow for payment end


    //checking if form is valid to proceed next and previous start
    const checkIsFormValidToProceed = () => {
        if (formStep === 'amount-selection') {
            chooseAmountValidation(true);
            // let amount = !selectedAmount ? !customAmount ? 0 : customAmount : selectedAmount;
            // if (!amount || !selectedDonationType) {
            //     setShowError(true);
            // }else{
            //     setFormStep('info');
            // }
        } else if (formStep === 'info') {
            if (!isValid) {
                trigger();
            } else {
                clearErrors();
                setFormStep('payment');
            }
        } else if (formStep === 'request_button') {
            setFormStep('info');
        }
    }
    //checking if form is valid to proceed next and previous end

    //creating payment intent start
    const [amount, setAmount] = useState(100);

    const [options, setOptions] = useState({
        mode: 'payment',
        amount,
        currency: '',
        paymentMethodCreation: 'manual',
        // Fully customizable with appearance API.
        appearance: appearance,
    });
    const [cardPaymentID, setCardPaymentID] = useState("");
    const getPaymentIntent = (amount) => {
        // GET_PAYMENT_INTENT
        const finalAmount =
            ((campaignDetail?.donation_amount?.ask_donors_cover_fee_default
                ? Math.round(parseFloat(calculateCCAfterFee((amount)) * 100)) : (amount * 100)))

        const amountAfterFee = finalAmount;
        axios.post(GET_PAYMENT_INTENT, { amount: amountAfterFee, stripe_account_id: campaignDetail?.stripe_account_id })
            .then((res) => {
                if (res?.data?.response?.client_secret) {
                    let clientSecret = res?.data?.response?.client_secret;

                    //this is an old implementation start
                    // setOptions(
                    //     { clientSecret, appearance }
                    // );
                    //this is an old implementation end

                    setCardPaymentID(res?.data?.response?.payment_id);
                    console.log(options)
                }
            }).catch((error) => {
                alert('Unable to create payment intent');
            })
    }
    //creating payment intent end


    //more options start
    const [isGoogleApplePay, setIsGoogleApplePay] = useState(false);
    const setPaymentFlow = (value) => {
        if (typeof (value) === 'boolean') {
            setIsGoogleApplePay(value);
        } else {
            setFormStep(value);
        }


    }
    //more options end

    const customAmountTemplateCopy = () => {
        return (
            <div className='col-11 offset-1 mt-4'>
                <div className="form-check">
                    <label className="form-check-label" htmlFor="amount">
                        <input {...register('selected_amount', {
                            required: true
                        })} className="form-check-input" type="radio"
                            value={'custom_amount'} />
                        <span className='form-check__item position-relative'>
                            <span className='f-18 position-absolute'>
                                USD$
                            </span>
                            <span className='form-check__item__amount f-24 d-block'>
                                <div className="form-group position-relative w-75">
                                    <input id='txtCustomAmount'
                                        style={{
                                            color: campaignDetail?.primary_color ? campaignDetail?.primary_color : ''
                                        }}
                                        disabled={selectedAmount}
                                        // onChange={selectCustomAmount}
                                        // value={customAmount}
                                        {...register('custom_amount', {
                                            validate: {
                                                required: value => {
                                                    if (value && !fieldsWatcher?.selected_amount) {
                                                        return 'Please select at-least one option';
                                                    } else if (!value && !fieldsWatcher?.selected_amount) return 'Amount is required';
                                                    return true;
                                                },
                                                min: value => {
                                                    if (campaignDetail?.donation_amount?.minimum_donation) {
                                                        if (value < campaignDetail?.donation_amount?.minimum_donation && (!fieldsWatcher?.selected_amount || fieldsWatcher?.selected_amount === 'custom_amount')) return `Minimum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.minimum_donation} is allowed`;
                                                    }else if(value < 0){
                                                        return `Minimum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}1 is allowed`
                                                    }
                                                    return true;
                                                },
                                                max: value => {
                                                    if (campaignDetail?.donation_amount?.max_donation) {
                                                        if (value > campaignDetail?.donation_amount?.max_donation && (!fieldsWatcher?.selected_amount || fieldsWatcher?.selected_amount === 'custom_amount')) return `Maximum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.max_donation} is allowed is allowed`;
                                                    }
                                                    return true;
                                                }
                                            }
                                        })}
                                        placeholder="Custom Amount" type="number"
                                        className="form-control-alternative custom-amount-input" />
                                    <span>Please Select Amount</span>
                                </div>
                                {
                                    errors?.custom_amount ?
                                        <FieldError message={
                                            errors?.custom_amount?.type === 'required' ?
                                                errors?.custom_amount?.message :
                                                errors?.custom_amount?.type === 'min' ?
                                                    `Minimum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.minimum_donation} is allowed.`
                                                    : errors?.custom_amount?.type === 'max' ?
                                                        `Maximum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.max_donation} is allowed is allowed.` : ''
                                        } /> : ''
                                }
                            </span>
                        </span>
                    </label>
                </div>
            </div>
        )
    }

    const customAmountTemplate = () => {
       return(
        <div className={`form-group position-relative ${descriptiveAmountView ? 'w-100' : 'w-75'}`}>
        <span style={{
             top: descriptiveAmountView ? '10px' : '22px'
        }} className="custom-amount-symbol">{CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}</span>
        <input onFocus={() => {
            if(descriptiveAmountView){
                setValue('selected_amount', "custom_amount")
            }
        }}
            // disabled={!descriptiveAmountView && fieldsWatcher?.selected_amount}
            {...register('custom_amount', {
                validate: {
                    required: value => {
                        // if(value && !fieldsWatcher?.selected_amount){
                        //     return 'Please select at-least one option';
                        // }else
                        if (!value && (!fieldsWatcher?.selected_amount || fieldsWatcher?.selected_amount === 'custom_amount')) return 'Amount is required';
                        return true;
                    },
                    min: value => {
                        if (campaignDetail?.donation_amount?.minimum_donation) {
                            if (value < campaignDetail?.donation_amount?.minimum_donation && (!fieldsWatcher?.selected_amount || fieldsWatcher?.selected_amount === 'custom_amount')) return `Minimum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.minimum_donation} is allowed`;
                        }else if(value < 0){
                            return `Minimum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}1 is allowed`
                        }
                        return true;
                    },
                    max: value => {
                        if (campaignDetail?.donation_amount?.max_donation) {
                            if (value > campaignDetail?.donation_amount?.max_donation && (!fieldsWatcher?.selected_amount || fieldsWatcher?.selected_amount === 'custom_amount')) return `Maximum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.max_donation} is allowed is allowed`;
                        }
                        return true;
                    }
                }
            })}
            onKeyUp={() => {
                if(descriptiveAmountView){
                    setValue("selected_amount","custom_amount")
                }else{
                    setValue("selected_amount","")
                }
                
            }}
            style={{
                color: campaignDetail?.primary_color ? campaignDetail?.primary_color : '',
                border: descriptiveAmountView ? 'none' : '',
                boxShadow: descriptiveAmountView ? 'none' : '',
                padding: descriptiveAmountView ? '5px 25px 5px 31px' : '12px 25px 14px 31px',
                height: descriptiveAmountView ? '30px' : '60px'
            }} placeholder="Custom Amount" type="number"
            className="form-control-alternative custom-amount-input" />
        {
            errors?.custom_amount ?
                <FieldError message={
                    errors?.custom_amount?.type === 'required' ?
                        errors?.custom_amount?.message :
                        errors?.custom_amount?.type === 'min' ?
                            `Minimum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.minimum_donation} is allowed.`
                            : errors?.custom_amount?.type === 'max' ?
                                `Maximum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.max_donation} is allowed is allowed.` : ''
                } /> : ''
        }
    </div>
       )
    }

    //calculating processing fee
    const [finalAmount, setFinalAmount] = useState(0);
    const calculateStripeFee = (event) => {
        if (event.target.checked) {
            const amount = returnSelectedAmount();
            setFinalAmount(parseFloat(calculateCCAfterFee(amount)));
        } else {
            setFinalAmount(returnSelectedAmount());
        }
    }

    const getDonationPercentage = () => {
        const percentage = ((raisedDonations?.raised / campaignDetail?.goal_amount) * 100);
        return (percentage > 100 ? 100 : percentage) + '%';
    }

    //register and unregister the processing fee checkbox
    useEffect(() => {
        if(formStep === 'payment' || formStep === 'amount-selection'){
            register('cover_processing_fee');
        }else{
            unregister('cover_processing_fee');
        }
    }, [formStep]);
    
    // const getFormData = () => {
    //     axios.get("https://api.jotform.com/submission/5808667874529936320?apiKey=e2285367e81ba58ba1408ae911cbae7e",{
    //         withCredentials: false
    //     })
    //     .then((res) => {
    //         console.log(res)
    //     }).catch((error) => {

    //     })
    // }
    //jotform implementation start
    // useEffect(() => {
    //     if(queryParams.get('email') && !stripeButtonElementLoader){
    //         let formFields = {}
    //         getFormData()
    //         queryParams.forEach((value, key) => {
    //             formFields[key] = value;
    //         });

    //         //now assigning jotform values to our form
    //         setSelectedDonationType('One-Time');
    //         reset({
    //             selected_amount: parseFloat(formFields?.amount),
    //             first_name: formFields?.first_name,
    //             last_name: formFields?.last_name,
    //             email: formFields?.email
    //         });
    //         setFormStep('info');
    //         console.log(getValues());
    //     }
    // }, [queryParams])

    //mapping implementation 
    const [formMappingLoader, setFormMappingLoader] = useState(
        (formID && submissionID) ? true : false
    );
    const [fieldValidations, setFieldValidations] = useState({});
    const getSubmissionData = () => {
        setFormMappingLoader(true);
        axios.get(GET_FORM_SUBMISSION + `${formID}/${submissionID}`)
        .then((res) => {
            setFormMappingLoader(false);
            if(res?.data?.status){
                setFieldValidations(res.data.response?.mapping);
                // setValue("custom_amount", 50);
                res.data.response.mappedJson.donation_type = 
                res.data.response.mappedJson.donation_type ? res.data.response.mappedJson.donation_type
                : 'One-Time'
                //checking if interval is not one-time then we will split the amount
                if(res.data.response.mappedJson.donation_type?.toLowerCase() !== 'one-time'){
                    if(res.data.response.mappedJson.recurring_option_value){
                        res.data.response.mappedJson.custom_amount = 
                        (parseFloat(res.data.response.mappedJson.custom_amount) / res.data.response.mappedJson.recurring_option_value);
                    }
                }

                //checking if interval is descriptive or not old code
                // const currentInterval = campaignDetail?.donation_amount?.amount_intervals?.find(it => it.type === 'One-Time');
                // if(currentInterval?.type){
                //     const isDescriptive = currentInterval?.amount_list?.some(it => it.description?.trim());
                //     if(isDescriptive){
                //         res.data.response.mappedJson.selected_amount = res.data.response.mappedJson.selected_amount?.toString();
                //     }
                    
                // }
                reset(res.data.response?.mappedJson);
                if(campaignDetail?.donation_amount?.ask_donors_cover_fee_default){
                    const amt = Math.round(parseFloat(calculateCCAfterFee((res.data.response?.mappedJson?.custom_amount)) * 100));
                    setAmount(amt);
                }else{
                    setAmount(res.data.response?.mappedJson?.custom_amount * 100);
                }
                
                // setSelectedDonationType('One-Time');
                selectDonationType(res.data.response.mappedJson.donation_type, true);
                isGoogleApplePay ? setFormStep('request_button') :  setFormStep('info');
                
            }
        }).catch((error) => {
            setFormMappingLoader(false);
        });
    }

    useEffect(() => {
        if(formID && submissionID && campaignDetail?.id && !stripeButtonElementLoader){
            if(Object.keys(fieldValidations)?.length === 0){
                getSubmissionData();
            }
        }
    }, [formID, submissionID, campaignDetail, stripeButtonElementLoader])
    //jotform implementation end

    useEffect(() => {
        if(!stripeButtonElementLoader){
            setLoader(false);
        }
    }, [stripeButtonElementLoader]);

    useEffect(() => {
        if(formStep === 'request_button'){
            if(!campaignDetail?.donation_amount?.ask_donors_cover_fee && campaignDetail?.donation_amount?.ask_donors_cover_fee_default){
                register("cover_processing_fee", {
                    value: true
                });
            }
        }
    }, [formStep])

    return (
        <>
            {
                campaignDetail?.is_delete ?
                    <div className='container'>
                        <div className='mt-5 col-10 offset-1' style={{ backgroundColor: 'aliceblue' }}>
                            <div className='row text-center'>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-xl-10 mt-lg-7 mt-md-3 mt-sm-3 mt-3 p-5'>
                                    <h4>Oops!</h4>
                                    <p className='mt-4'>We’ve tried searching but couldn’t find the page you’re looking for.</p>
                                    <p className='mt-4'>Not to worry — let’s find a better place for you to go.</p>
                                    <p className='mt-4'>
                                        Are you a Masjidal organization looking for your campaign? click <a className='text-light-blue' href='https://donation.dev.masjidal.com/'>here</a>.</p>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                                    <img src='../../../img/404.jpg' alt='404' className='w-100' />
                                </div>
                            </div>
                        </div>

                    </div> : (campaignDetail?.activate_pledge === false && pathName?.includes("pledge")) ? 
                    <div className='container'>
                    <div className='mt-5 p-5 col-6 offset-3 border-radius-10' style={{ backgroundColor: 'aliceblue' }}>
                        <div className='row text-center'>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                <i style={{
                                    color:'#344767'
                                }} className="fa-solid fa-hand-holding-dollar f-45 pledge-icon"></i>
                                <h4 className='mt-3'>Oops!</h4>
                                <p className='mt-4'>
                                    Pledge option for this campaign is not active.
                                </p>
                                <p>You can activate pledge option from campaign settings.</p>
                            </div>
                        </div>
                    </div>
                </div>
                     : campaignDetail?.donation_amount?.id ? <FormProvider {...methods}>
                        {
                            campaignDetail?.is_display_logo || !campaignDetail?.is_display_campaign_title
                                || campaignDetail?.is_subtitle ?
                                <div className='custom-page-header'>
                                    {
                                        campaignDetail?.is_display_logo && campaignDetail?.image_location ?
                                            <div className='col-12 mt-3'>
                                                <img className='donation-widget-logo' src={`${logoBaseUrl}${campaignDetail?.image_location}`} alt="Masjid Image" />
                                            </div> : ''
                                    }


                                    {
                                        !campaignDetail?.is_display_campaign_title ?
                                            <div className='col-12 mt-3'>
                                                <span style={{
                                                    color:
                                                        (campaignDetail?.is_header_background_color ?
                                                            getTextColorByBG(campaignDetail?.header_background_color) : 'black')
                                                }} className='custom-header-title'>{campaignDetail?.campaign_title}</span>
                                            </div> : ''
                                    }

                                    {
                                        campaignDetail?.is_subtitle ?
                                            <div className='col-12 mt-2 mb-4'>
                                                <span style={{
                                                    color:
                                                        (campaignDetail?.is_header_background_color ?
                                                            getTextColorByBG(campaignDetail?.header_background_color) : 'black')
                                                }} className='custom-header-subtitle'>{campaignDetail?.subtitle}</span>
                                            </div> : ''
                                    }

                                </div> : ''
                        }


                        {
                            campaignDetail?.is_header_background_color ?
                                <div style={{ backgroundColor: campaignDetail?.header_background_color }} className='form--header'></div>
                                : ''
                        }


                        <div>
                            {
                                <div className='container' style={{
                                    display: loader ? 'none' : 'block'
                                }}>
                                    <div className='col-xl-4 col-lg-6 col-md-8 col-sm-12 col-xs-12 offset-md-2 offset-xl-4 offset-lg-3'>
                                        {/* {
                                    !campaignDetail?.is_display_campaign_title ?
                                        <div className='text-center mt-3'>
                                            <p className=' f-20 text-black'>{campaignDetail?.campaign_title}</p>
                                        </div> : ""
                                } */}

                                        {
                                            parseFloat(campaignDetail?.goal_amount) && formStep !== 'confirmation' && 
                                            !campaignDetail?.activate_pledge ?
                                                <div className='row mt-3 position-relative'>
                                                    <div className='col-12'>
                                                        <div className='donation-widget'>
                                                            <div className='donation'>
                                                                <div className='donation__received' style={{ 
                                                                    width: getDonationPercentage(),
                                                                    backgroundColor: campaignDetail?.primary_color ? (campaignDetail?.primary_color) : '#015B97'
                                                                }}></div>
                                                            </div>

                                                            <div className='d-flex justify-content-between align-items-center p-4'>
                                                                <div className='text-center d-grid'>
                                                                    <span className='text-black'>{CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}{raisedDonations?.raised ? Math.round(raisedDonations?.raised) : ''}</span>
                                                                    <span className='text-black'>Raised</span>
                                                                </div>
                                                                <div className='text-center d-grid'>
                                                                    <span className='text-black'>{raisedDonations?.donations}</span>
                                                                    <span className='text-black'>Donations</span>
                                                                </div>
                                                                <div className='text-center d-grid'>
                                                                    <span className='text-black'>{CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}{campaignDetail?.goal_amount}</span>
                                                                    <span className='text-black'>Goal</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> : ''
                                        }

                                        <div className={`card mt-3 donation-header ${formMappingLoader ? 'd-none' : ''}`}>
                                            {/* {formMappingLoader ? <Loader isWidget={true} /> : ""} */}
                                            <div className="border-0 card-header donation-header" style={{
                                                backgroundColor: campaignDetail?.primary_color ? (campaignDetail?.primary_color) : '#005B97'
                                            }}>
                                                <div className="align-items-center row">
                                                    <div className="col-12 text-left">
                                                        <div className="mb-0 text-white d-flex justify-content-between align-items-center">

                                                            <div>
                                                                {(formStep === 'info' && (!isGoogleApplePay ? (!formID && !submissionID) : true)) || formStep === 'payment' || (formStep === 'request_button' && !formID && !submissionID) ?
                                                                    <span onClick={(() => {
                                                                        
                                                                        setFormStep(
                                                                            (formStep === 'info' && isGoogleApplePay && campaignDetail?.google_apple_pay) ? 'request_button' :
                                                                                formStep === 'info' ? 'amount-selection' :
                                                                                    formStep === 'request_button' ? 'amount-selection' : 'info'
                                                                        )
                                                                    })} className="f-22 cursor-pointer fa-solid fa-arrow-left me-5"></span> : ''
                                                                }

                                                                <span className='f-22'>
                                                                    {formStep === 'amount-selection' ? 'Choose Amount' :
                                                                     formStep === 'info' ? ((campaignDetail?.activate_pledge && donorInfoRef?.current?.payLater) ? 'Pledge Form' : 'Information') :
                                                                     formStep === 'request_button' ? 'Payment' :
                                                                        formStep === 'payment' ?
                                                                            'Payment' : 'Success'}
                                                                </span>
                                                            </div>
                                                            {
                                                                (formStep !== 'confirmation' && formStep !== 'pledge-confirmation') ?
                                                                    <span>
                                                                        <i className={`fa-circle me-2 ${formStep === 'amount-selection' ? 'fa-solid' : 'fa-regular'}`}></i>
                                                                        {(isGoogleApplePay && !campaignDetail?.activate_pledge && !donorInfoRef?.current?.payLater) ? <i className={`fa-circle me-2 ${formStep === 'request_button' ? 'fa-solid' : 'fa-regular'}`}></i> : ''}
                                                                        <i className={`fa-circle me-2 ${formStep === 'info' ? 'fa-solid' : 'fa-regular'}`}></i>
                                                                        {(isGoogleApplePay && !campaignDetail?.activate_pledge && !donorInfoRef?.current?.payLater) ? <i className={`fa-circle me-2 ${formStep === 'payment' ? 'fa-solid' : 'fa-regular'}`}></i> : ''}
                                                                    </span> : ''
                                                            }
                                                            {/* <span className='f-18'>
                                                    {campaignDetail?.campaign_title ? `  (${campaignDetail?.campaign_title})` : ''}
                                                </span> */}
                                                            {
                                                                (formStep !== 'confirmation' && formStep !== 'pledge-confirmation' && formStep !== 'payment' && (formStep === 'info' ? 
                                                                campaignDetail?.activate_pledge && donorInfoRef?.current?.payLater ? false : true : true
                                                                )) ?
                                                                    <i onClick={checkIsFormValidToProceed} className="f-24 float-end cursor-pointer fa-solid fa-arrow-right me-2"></i> :
                                                                    ''
                                                            }

                                                        </div>
                                                        {/* <p className='text-white m-0 text-bold f-15'>Goal Amount: {campaignDetail?.goal_amount ? formateCashNumer(campaignDetail?.goal_amount) : '0'}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body main-widget p-4">


                                                <div className={formStep !== 'amount-selection' ? 'd-none' : ''}>

                                                    {campaignDetail?.is_call_to_action && campaignDetail?.call_to_action_message ? <p className='text-center text-black'>{campaignDetail?.call_to_action_message}</p> : ''}

                                                    {/* d-flex justify-content-center align-items-center flex-wrap */}
                                                    <div className='payment_list'>

                                                        <div className={`mt-3 d-flex justify-content-center align-items-center flex-wrap`}>
                                                            {
                                                                donationTypes.map((value, index) => {
                                                                    return <div
                                                                        onClick={(() => selectDonationType(value.name))} key={index}
                                                                        style={{
                                                                            border: campaignDetail?.primary_color ? ('1px solid ' + campaignDetail?.primary_color) : '1px solid #005b97',
                                                                            background: selectedDonationType === value?.name ? campaignDetail?.primary_color : ''
                                                                        }}
                                                                        className={`cursor-pointer payment-type f-14 mt-2 pe-3 px-3 ${selectedDonationType === value?.name ? 'selected' : ''}`}>
                                                                            {value?.name === 'Every-Jumuah' ? 'Every Jumuah' : value?.name}
                                                                        </div>
                                                                        // col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5
                                                                })
                                                            }
                                                        <input type='radio' className='d-none' {...register("selected_amount",{
                                                             validate:{
                                                                required: value => {
                                                                    if(!value && !fieldsWatcher?.custom_amount){
                                                                        return 'You need to select at-least one amount'
                                                                    }
                                                                    return true
                                                                }
                                                            }
                                                        })} />
                                                        </div>
                                                    </div>
                                                    {
                                                        selectedDonationType !== 'One-Time' ? 
                                                        campaignDetail?.recurring_option === 'end_by_date' ? 
                                                        <p className='text-center mt-2 mb-0 f-14 text-bold'>Recurring donation will end on {getFormattedDate(campaignDetail?.recurring_option_value)}</p> :
                                                        campaignDetail?.recurring_option === 'charge_fixed_number_of_times' ? 
                                                        <p className='text-center mt-2 mb-0 f-14 text-bold'>Recurring will end after {campaignDetail?.recurring_option_value} payments</p> : '' : ''
                                                    }
                                                    {/* <div className='row mt-4 text-center justify-content-center'>
                                            <div className='col-3 mt-1'>
                                                <div onClick={(() => selectAmount('10'))} 
                                                className={`amount-selection cursor-pointer ${selectedAmount === '10' ? 'selected' : ''}`}
                                                style={{
                                                    border: selectedAmount === '10' ? ('1px solid ' + campaignDetail?.primary_color) : '',
                                                    color: selectedAmount === '10' ? campaignDetail?.primary_color : ''
                                                }}
                                                >
                                                    <span className='f-12'>$10</span>
                                                </div>
                                            </div>

                                            <div className='col-3 mt-1'>
                                                <div onClick={(() => selectAmount('50'))} 
                                                style={{
                                                    border: selectedAmount === '50' ? ('1px solid ' + campaignDetail?.primary_color) : '',
                                                    color: selectedAmount === '50' ? campaignDetail?.primary_color : ''
                                                }}
                                                className={`amount-selection cursor-pointer ${selectedAmount === '50' ? 'selected' : ''}`}>
                                                    <span className='f-12'>$50</span>
                                                </div>
                                            </div>

                                            <div className='col-3 mt-1'>
                                                <div onClick={(() => selectAmount('100'))} 
                                                 style={{
                                                    border: selectedAmount === '100' ? ('1px solid ' + campaignDetail?.primary_color) : '',
                                                    color: selectedAmount === '100' ? campaignDetail?.primary_color : ''
                                                }}
                                                className={`amount-selection cursor-pointer ${selectedAmount === '100' ? 'selected' : ''}`}>
                                                    <span className='f-12'>$100</span>
                                                </div>
                                            </div>
                                        </div> */}

                                                    {
                                                        currentDisplayingAmount?.amount_list?.length > 0 ?
                                                            <div className='row mt-2 text-center justify-content-center'>
                                                                {
                                                                    currentDisplayingAmount?.amount_list?.map((item, index) => {
                                                                        return (
                                                                                
                                                                                    descriptiveAmountView ? 
                                                                                    <div key={`${index}_${item?.id}`} className={` mt-4`}>
                                                                                <div className='descriptive-amount form-check'>
                                                                                <input id={`${index}_${item?.id}`} 
                                                                                {...register('selected_amount',{
                                                                                        onChange: (e) => {
                                                                                            reset({
                                                                                                selected_amount: e.target.value,
                                                                                                custom_amount: null,
                                                                                                recurring_option_value: fieldsWatcher?.recurring_option_value,
                                                                                                is_charge_number_of_times: fieldsWatcher?.is_charge_number_of_times
                                                                                            });
                                                                                            setCustomAmount('')
                                                                                        },
                                                                                            validate:{
                                                                                                required: value => {
                                                                                                    if(!value){
                                                                                                        return 'You need to select at-least one amount'
                                                                                                    }
                                                                                                    return true
                                                                                                }
                                                                                            }
                                                                                        })} 
                                                                                         type="radio"
                                                                                        value={item?.amount} />
                                                                                    <label htmlFor={`${index}_${item?.id}`} className='d-flex justify-content-center align-items-center'>
                                                                                        
                                                                                        <span style={{
                                                                                            display:'flex',
                                                                                            alignItems:'center',
                                                                                            justifyContent:'space-between'
                                                                                        }}>
                                                                                            <span className='f-18 p-1'>
                                                                                                {CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}
                                                                                            </span>
                                                                                            <span className='f-24 text-black'>
                                                                                                {item?.amount}
                                                                                            </span>
                                                                                            <sup className='f-16 p-1'>
                                                                                            {item?.description}
                                                                                            </sup>
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div> : 
                                                                            <div key={`${index}_${item?.id}`} className='col-3 mt-1'>
                                                                                <div onClick={(() => selectAmount(item?.amount))}
                                                                                    className={`amount-selection cursor-pointer ${fieldsWatcher?.selected_amount === item?.amount ? 'text-bold' : ''}`}
                                                                                    style={{
                                                                                        border: fieldsWatcher?.selected_amount === item?.amount ? ('1px solid ' + campaignDetail?.primary_color) : '',
                                                                                        color: fieldsWatcher?.selected_amount === item?.amount ? campaignDetail?.primary_color : '',
                                                                                        boxShadow: fieldsWatcher?.selected_amount === item?.amount ? ('0px 0px 12px 0px ' + campaignDetail?.primary_color) : ''
                                                                                    }}
                                                                                >
                                                                                    <span className='f-12'>{CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}{item?.amount}</span>
                                                                                </div>
                                                                            </div>
                                                                                
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    errors?.selected_amount ?
                                                                        <FieldError message={errors?.selected_amount?.message} /> : ''
                                                                }

                                                                {/* {
                                                !campaignDetail?.donation_amount?.not_allow_custom_donation &&
                                                currentDisplayingAmount?.amount_list?.length > 0 ?
                                                customAmountTemplate() : ''
                                           } */}
                                                                {
                                                                    (!campaignDetail?.donation_amount?.not_allow_custom_donation &&
                                                                        currentDisplayingAmount?.amount_list?.length > 0) ?
                                                                        <div className={`mt-3 ${!descriptiveAmountView ? 'd-flex justify-content-center' : ''}`}>
                                                                            {
                                                                                descriptiveAmountView ? 
                                                                                <div className='descriptive-amount form-check'>
                                                                            <input id="customAmount" {...register('selected_amount',{
                                                                                            validate:{
                                                                                                required: value => {
                                                                                                    if(!value){
                                                                                                        return 'You need to select at-least one amount'
                                                                                                    }
                                                                                                    return true
                                                                                                }
                                                                                            }
                                                                                        })} 
                                                                                         type="radio"
                                                                                        value={'custom_amount'} />
                                                                                        <label htmlFor='customAmount'>
                                                                                            {
                                                                                                customAmountTemplate()
                                                                                            }
                                                                                        </label>
                                                                            </div> : customAmountTemplate()
                                                                            }

                                                                        </div> : ''
                                                                }

                                                                {/* {JSON.stringify(fieldsWatcher)} */}
                                                            </div> :


                                                            !campaignDetail?.donation_amount?.not_allow_custom_donation ?

                                                                <div className='row mt-3'>
                                                                    <div className="col-12 mt-3 d-flex justify-content-center">
                                                                        <div className="form-group position-relative w-75">
                                                                            <span className="custom-amount-symbol standalone">{CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}</span>
                                                                            <input
                                                                                style={{
                                                                                    color: campaignDetail?.primary_color ? campaignDetail?.primary_color : ''
                                                                                }}
                                                                                placeholder="Custom Amount" type="number"
                                                                                {...register('custom_amount', {
                                                                                    validate: {
                                                                                        required: value => {
                                                                                            if (!value) return 'Amount is required';
                                                                                            return true;
                                                                                        },
                                                                                        min: value => {
                                                                                            if (campaignDetail?.donation_amount?.minimum_donation) {
                                                                                                if (value < campaignDetail?.donation_amount?.minimum_donation) return `Minimum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.minimum_donation} is allowed`;
                                                                                            }else if(value < 0){
                                                                                                return `Minimum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}1 is allowed`
                                                                                            }
                                                                                            return true;
                                                                                        },
                                                                                        max: value => {
                                                                                            if (campaignDetail?.donation_amount?.max_donation) {
                                                                                                if (value > campaignDetail?.donation_amount?.max_donation) return `Maximum ${CURRENCY_CODE_SIGN_MAPPING[campaignDetail?.currency]}${campaignDetail?.donation_amount?.max_donation} is allowed is allowed`;
                                                                                            }
                                                                                            return true;
                                                                                        }
                                                                                    }
                                                                                })}
                                                                                className="form-control-alternative custom-amount-input standalone" />
                                                                            {
                                                                                errors?.custom_amount ?
                                                                                    <FieldError message={
                                                                                        errors?.custom_amount?.message
                                                                                    } /> : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : ''

                                                    }

                                                    {/* <div className="row">
                                    <div className='col-lg-4'>
                                        $10
                                    </div>
                                    <div className='col-lg-4'>
                                        $50
                                    </div>
                                    <div className='col-lg-4'>
                                        $100
                                    </div>
                              <div className="col-lg-12 mt-3">
                                  <div className="form-group">
                                      <label className="form-control-label">
                                          Language
                                      </label>
                                      <input placeholder="Language" defaultValue={'English'} type="text"
                                          className="form-control-alternative form-control" /></div>
                              </div>
                          </div> */}
                                                    <div className="row mt-3">
                                                        {/* <div className="col-12 mt-3 d-flex justify-content-center">
                                                <div className="form-group position-relative w-75">
                                                    <span className="custom-amount-symbol">$</span>
                                                <input id='txtCustomAmount' 
                                                style={{
                                                    color: campaignDetail?.primary_color ? campaignDetail?.primary_color : ''
                                                }}
                                                disabled={selectedAmount} onChange={selectCustomAmount} value={customAmount} placeholder="Custom Amount" type="number"
                                                    className="form-control-alternative custom-amount-input" />
                                                     {
                                                showError ?
                                                    <FieldError message={'Donation type and amount is required'} /> : ''
                                            }
                                                    </div>
                                                    
                                            </div> */}
                                                    {
                                                        selectedDonationType !== 'One-Time' && campaignDetail?.recurring_option === 'allow_user_to_select_number_of_times' ? 
                                                        <ChargeFixedNumberOfTime descriptiveAmountView={descriptiveAmountView} /> : ''
                                                    }
                                                    
                                                        {
                                                            campaignDetail?.is_donation_designation && campaignDetail?.designation_dropdown_value ?
                                                                <DonationDesignationTemplate
                                                                descriptiveAmountView={descriptiveAmountView}
                                                                    register={register}
                                                                    errors={errors}
                                                                    campaignDetail={campaignDetail} />
                                                                : ''
                                                        }

                                                        {/* donor comments section  */}
                                                        {
                                                            campaignDetail?.is_donor_comments ? 
                                                            <DonorCommentsTemplate
                                                            descriptiveAmountView={descriptiveAmountView}
                                                             settings={campaignDetail} /> : ""
                                                        }

                                                        <div className="col-lg-12 mt-4">
                                                            <div className="text-center position-relative">
                                                            {
                                                                    campaignDetail?.activate_pledge ? 
                                                                    <button disabled={stripeButtonElementLoader} onClick={() => chooseAmountValidation(true)}
                                                                    style={{
                                                                        backgroundColor: campaignDetail?.primary_color ? (campaignDetail?.primary_color) : '#005B97',
                                                                        borderBottom: campaignDetail?.primary_color ? ('2px solid ' + campaignDetail?.primary_color) : ''
                                                                    }}
                                                                    type="button" className={`btn btn-md w-75 text-white btn-step-one`}>
                                                                    <span>
                                                                        <span className='f-18 mx-4'>
                                                                            Pay Later (Pledge)
                                                                        </span> <i className="fa-solid fa-business-time f-18"></i>
                                                                    </span>
                                                                </button> : ''
                                                                }
                                                                <button disabled={stripeButtonElementLoader} onClick={() => chooseAmountValidation(false)}
                                                                    style={{
                                                                        backgroundColor: campaignDetail?.primary_color ? (campaignDetail?.primary_color) : '#005B97',
                                                                        borderBottom: campaignDetail?.primary_color ? ('2px solid ' + campaignDetail?.primary_color) : ''
                                                                    }}
                                                                    type="button" className={`btn btn-md w-75 text-white btn-step-one`}>
                                                                    <span>
                                                                        <span className='f-18 mx-4'>
                                                                            {campaignDetail?.activate_pledge ? 'Pay Now' : 'Next'}
                                                                        </span> <i className="fa-solid fa-arrow-right f-18"></i>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={formStep !== 'request_button' || !campaignDetail?.google_apple_pay ? 'd-none' : ''}>

                                                    {/*  implementing new logic for stripe button element start*/}
                                                    <Elements stripe={stripePromise}>
                                                        <PaymentRequestForm
                                                        setStripeButtonElementLoader={setStripeButtonElementLoader}
                                                            formStep={formStep}
                                                            selectedDonationType={selectedDonationType}
                                                            onPaymentSuccess={onPaymentSuccess}
                                                            returnDonationPayload={returnDonationPayload}
                                                            calculateStripeFee={calculateStripeFee}
                                                            setPaymentFlow={setPaymentFlow}
                                                            options={options}
                                                            amount={returnSelectedAmount()}
                                                            campaignDetail={campaignDetail}
                                                        />
                                                    </Elements>

                                                    {/*  implementing new logic for stripe button element end*/}
                                                </div>




                                                <div className={formStep !== 'info' ? 'd-none' : ''}>
                                                    <PledgeUserInfo ref={donorInfoRef}
                                                    setFormStep={setFormStep}
                                                    fieldValidations={fieldValidations}
                                                        errors={errors}
                                                        donorInfo={donorInfo}
                                                        returnSelectedAmount={returnSelectedAmount}
                                                        form={getValues()}
                                                        selectedDonationType={selectedDonationType}
                                                        campaignDetail={campaignDetail} />
                                                </div>



                                                <div className={`col-12 ${formStep !== 'payment' ? 'd-none' : ''}`}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/visa.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/master.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/amex.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/discover.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/jcb.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/union_pay.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                    </div>

                                                    {/* render payment form start */}
                                                    
                                                        <div className="mt-4">
                                                            {
                                                                campaignDetail?.id ?
                                                                    <Elements stripe={stripePromise} options={{
                                                                        mode: 'payment',
                                                                        amount,
                                                                        currency: campaignDetail?.currency,
                                                                        paymentMethodCreation: 'manual',
                                                                        // Fully customizable with appearance API.
                                                                        appearance: appearance,
                                                                    }}>
                                                                        {/* adding payment options for card and google pay apple pay */}
                                                                        <PaymentForm
                                                                            ref={requestFormRef}
                                                                            formStep={formStep}
                                                                            setAmount={setAmount}
                                                                            formValues={getValues()}
                                                                            fieldsWatcher={fieldsWatcher}
                                                                            returnSelectedAmount={returnSelectedAmount}
                                                                            onPaymentSuccess={onPaymentSuccess}
                                                                            selectedDonationType={selectedDonationType}
                                                                            campaignDetail={campaignDetail}
                                                                            returnDonationPayload={returnDonationPayload}
                                                                            savePaymentInfoLoader={savePaymentInfoLoader}
                                                                            cardPaymentID={cardPaymentID}

                                                                            clientSecret={options?.clientSecret}
                                                                        />
                                                                    </Elements> : ''
                                                            }
                                                        </div>
                                                    {/* render payment form end */}


                                                    {/* <div className='col-lg-12 mt-4'>
                                <div className="form-group"><label className="form-control-label">Card Number</label>
                                    <input placeholder="Credit Card Number" type="text"
                                        className="form-control-alternative form-control" /></div>
                            </div>
                                <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">Expiration MM / YY</label>
                                    <input placeholder="Card Expiration" type="text"
                                        className="form-control-alternative form-control" /></div>
                            </div>
                                <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">CVC Code</label>
                                    <input placeholder="Credit Card Number" type="text"
                                        className="form-control-alternative form-control" /></div>
                            </div> */}

                                                </div>

                                                <div className={`row ${formStep !== 'confirmation' ? 'd-none' : ''}`}>
                                                    <div className="col-lg-12 text-center">
                                                        <i className="fa-regular fa-circle-check" style={{ fontSize: '5rem', color: '#52a355' }}></i>
                                                        <h5 className='mt-3 mb-0'>You Have Donated ${finalAmount?.toFixed(2)}</h5>
                                                        <span>{new Date().toDateString().split(" ")[0] + ' ' + getFormattedDateTime(new Date())}</span>
                                                        <p className='mt-4 mb-0 text-black'>
                                                            {
                                                                campaignDetail?.success_message ?
                                                                    campaignDetail?.success_message :
                                                                    'Thank you for your donation.'
                                                            }
                                                        </p>

                                                        <p className='text-black'>A receipt has been emailed to you.</p>

                                                        <button
                                                             style={{
                                                                backgroundColor: campaignDetail?.primary_color ? (campaignDetail?.primary_color) : '#005B97',
                                                                borderBottom: campaignDetail?.primary_color ? ('2px solid ' + campaignDetail?.primary_color) : ''
                                                            }}
                                                            onClick={(() => {
                                                                window.open(campaignDetail?.website ? campaignDetail?.website : 'https://mymasjidal.com/', '_self', '');
                                                            })}
                                                            type="button" className={`btn text-white btn-md w-70 `}>
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>

                                                {
                                                    donorInfoRef?.current?.payLater ? 
                                                    <PledgeConfirmation 
                                                    formStep={formStep}
                                                    returnSelectedAmount={returnSelectedAmount}
                                                    campaignDetail={campaignDetail}
                                                     /> : ''
                                                }

                                                <div className='text-center anchor mt-3'>
                                                    <a href='https://masjidal.com/' target='_blank'>Powered by Masjidal</a>
                                                    {/* {JSON.stringify(fieldsWatcher)} */}
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <p className='text-center mt-3'>{campaignDetail?.donation_amount?.compliance}</p>
                                </div>

                            }
                        </div>
                    </FormProvider> : ''
            }


        </>


    );
}

export default DonationPledge;