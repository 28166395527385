import React, { useEffect, useState } from "react";
import Loader from "../../../../shared/loader/loader";
import NoDataComponent from "../../../../shared/no-data/no-data";
import moment from "moment";
import { PAYMENT_STATUS } from "../../../../constants/payment-status.constant";
import { Link, useOutletContext } from "react-router-dom";

function DonationsByPledge({pledge}) {
  //props 
  const [donationList, setDonationList] = useState([]);
  const [loader, setLoader] = useState(false);
  //getting route context
  const {currencySign} = useOutletContext();

  //methods

  useEffect(() => {
    if(pledge?.id){
        setDonationList(pledge?.donation);
    }
  }, [pledge])

  return (
    <div className="row mt-3 position-relative">
      <div className="col-xl-12">
        <div className="card">
          <div className="table-responsive">
            {!loader ? (
              <table className="table align-items-center ">
                <thead className="text-center prayers-table-header white">
                  <tr>
                    <th>ID & Date</th>
                    <th>Campaign</th>
                    <th>Donor</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="prayer-table-body text-center">
                  {donationList.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <Link
                            to={`/donations/detail/${item.id}/${pledge?.campaign_id}`}
                            className="text-light-blue"
                          >
                            {item?.id.split("-")[0]?.toUpperCase()}
                          </Link>
                          <p className="mb-0">
                            {item?.platform_used === "Offline"
                              ? moment(item?.created_at)
                                  .utc()
                                  .format("MM/DD/YYYY")
                              : moment(item?.created_at)
                                  .format("MM/DD/YYYY hh:mm")}
                          </p>
                        </td>
                        <td>{pledge?.campaign?.campaign_title}</td>
                        <td>
                          <Link
                            to={`/donor-detail/${pledge?.donor?.donor_id}/${
                              pledge?.donor?.first_name +
                              " " +
                              pledge?.donor?.last_name
                            }`}
                            className="text-light-blue"
                          >
                            {pledge?.donor?.first_name +
                              " " +
                              pledge?.donor?.last_name}
                          </Link>
                        </td>
                        <td>{currencySign}{item?.amount / 100}</td>
                        <td
                          title={
                            item?.donationStatus?.payment_log
                              ? item?.donationStatus?.payment_log
                              : "Payment is in process"
                          }
                          className={`text-bold text-capitalize ${
                            item?.donationStatus?.payment_status ===
                            PAYMENT_STATUS.SUCCESS
                              ? "text-success"
                              : item?.donationStatus?.payment_status ===
                                PAYMENT_STATUS.FAILED
                              ? "text-danger"
                              : !item?.donationStatus?.payment_status
                              ? "text-warning"
                              : ""
                          }`}
                        >
                          {item?.donationStatus?.payment_status
                            ? item?.donationStatus?.payment_status
                            : "Pending"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              ""
            )}

            {/* no data message */}
            {!loader && donationList?.length === 0 ? (
              <NoDataComponent message={"No Donations Found"} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader ? <Loader /> : ""}
    </div>
  );
}

export default DonationsByPledge;
