import axios from 'axios';
import { useEffect, useRef, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import {  COPY_PRODUCT, DELETE_PRODUCT, GET_ALL_PRODUCTS } from '../../../constants/endpoints';
import '../campaigns/campaigns.component.scss'
import { Dropdown, Form } from 'react-bootstrap';
import getFormattedDate from '../../../utils/date-format';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';
import Loader from "../../../shared/loader/loader";
import { getSetOrgInfo, getUserInfo } from '../../../utils/user-info';
import LinkQRCode from '../../../shared/qr-code/qr-code';
import useRemoveQRCode from '../../../hooks/useRemoveQRCodeHook';
import CopyCampaignDialog from '../../../shared/copy-campaign-dialog/copy-campaign-dialog';

const Products = () => {

    const confirmationDialogRef = useRef(null);
    const copyCampaignDialogRef = useRef(null);
    const [dataLoader, setDataLoader] = useState(false);
    const userInfo = getUserInfo();
    const [productList, setProductList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('active');
    const [searchString, setSearchString] = useState('');
    const [sorting, setSorting] = useState('name:asc');
    const [qrCode,setQrCode] = useState('');
    const organizationInfo = getSetOrgInfo();
    const removeQRCode = useRemoveQRCode(setQrCode);
    //getting route context
    const {currencySign} = useOutletContext();

    const handleStatus = (event) => {
        setSelectedStatus(event.target.value);
    }

    
    const getAllProducts = (searchValue) => {
      setDataLoader(true);
        const formData = {
          status: selectedStatus,
          search: (searchValue ? searchValue : searchString ? searchString : ''),
          sort: sorting
        }
        axios.post((GET_ALL_PRODUCTS + 'portal'), formData)
        .then((res) => {
          setDataLoader(false);
            if(res?.data?.status){
              setProductList(res?.data?.response);
            }
        }).catch((error) => {
          setDataLoader(false);
        });
    }

    //searching campaigns
    const searchProduct = (value) => {
      getAllProducts(value || null);
    }

    useEffect(() => {
        if(selectedStatus || sorting){
            getAllProducts();
        }
    },[selectedStatus, sorting]);

    
    const openConfirmDelete = (product) => {
        let text = `Are you sure you want to ${product?.product_is_delete ? 'Unarchive' : 'Archive'} "${product?.product_campaign_title}" product.`;
        confirmationDialogRef.current.setDialogTitle(`Confirm ${product?.product_is_delete ? 'Unarchive' : 'Archive'}`)
        confirmationDialogRef.current.setMessage(text)
        confirmationDialogRef.current.setButtonLabel((product?.product_is_delete ? 'Unarchive' : 'Archive'))
        confirmationDialogRef.current.openDialog(product);
    }
    
    const deleteProduct = (product) => {
        axios.delete(DELETE_PRODUCT + product?.product_id + '?archive='+(product?.product_is_delete ? 'unarchive' : 'archive'))
        .then((res) => {
            
            if(res?.data?.status){
                    getAllProducts();
            }else{
              alert('Unable to delete product.');
            }
        }).catch((error) => {
            alert('Unable to delete product.');
        })
    }
    //html side start
    return (
      <>
        <div className="row">
          {/* <ModalComponent /> */}
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                <h5 className="text-black">Products</h5>
              </div>
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 text-right f-14">
                <Link
                  to="/layout/edit-store-page/product"
                  className="add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3 me-2"
                >
                  <span>
                    <i className="fa-solid fa-pen-to-square mr-5"></i>
                  </span>
                  <span className="ms-2 text-white">Edit Product Page</span>
                </Link>
                <Link
                  to="/layout/product/edit"
                  className="add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3"
                >
                  <span>
                    <i className="fa-solid fa-circle-plus mr-5"></i>
                  </span>
                  <span className="ms-2 text-white">Add Product</span>
                </Link>
              </div>
            </div>

            <div className="row mb-5">
            <div className="col-xl-12 mb-3">
                <a target='_blank' href={`/${organizationInfo?.organization_short_name}/product`} className="text-cobalt-light">
                  View Masjid Product Page
                </a>
              </div>
              <div className="col-md-2 mt-2">
                <div className="form-group">
                  <Form.Select
                    value={selectedStatus}
                    onChange={handleStatus}
                    aria-label="Default select example"
                  >
                    <option value={"active"}>Active</option>
                    <option value={"archive"}>Archived</option>
                  </Form.Select>
                </div>
              </div>
              <div className="col-md-2 mt-2">
                <div className="form-group">
                  <select
                    value={sorting}
                    onChange={(e) => setSorting(e.target.value)}
                    className="form-select"
                  >
                    <option value={"name:asc"}>Sort by name</option>
                    <option value={"date:asc"}>From oldest to newest</option>
                    <option value={"date:desc"}>From newest to oldest</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <div className="form-group">
                  <div className="input-group">
                    {/* <span className="input-group-text text-body">
                      <i className="fas fa-search" aria-hidden="true"></i>
                    </span> */}
                    <input
                      value={searchString}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setSearchString(e.target.value);
                          searchProduct(e.target.value);
                        }
                      }}
                      onChange={(e) => setSearchString(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Search by product name"
                    />
                    <button
                      onClick={() => searchProduct()}
                      type="button"
                      className="btn btn-outline-secondary mb-0"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row position-relative">
              {productList.map((product, index) => {
                return (
                  <div key={index} className="col-xl-6 mb-3">
                    <div className="campaign-card">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className="mb-0">
                            <a
                              href={`/layout/product/edit/?id=${product?.product_id}`}
                              target="_self"
                              className="mb-0 f-16 text-bold"
                            >
                              {product.product_campaign_title}
                            </a>
                          </p>
                          <span className="f-12-small">
                            {getFormattedDate(product.product_created_at)}
                          </span>
                        </div>

                        <div className="d-flex justify-content-end align-items-baseline">
                          <Link
                            to={`/layout/product/edit/?id=${product?.product_id}`}
                            className="me-2 cursor-pointer camp-actions"
                          >
                            <i className="fa-regular fa-pen-to-square f-14"></i>{" "}
                            <span className="d-none d-sm-inline-block f-14">
                              Edit Product
                            </span>
                          </Link>

                          <Dropdown>
                            <Dropdown.Toggle
                              className="mb-0 camp-actions action-toggle"
                              id="dropdown-basic"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item 
                              onClick={() => copyCampaignDialogRef?.current?.handleShow(product)}>
                                Copy
                              </Dropdown.Item>
                              {/* <Dropdown.Item>Embedded codes</Dropdown.Item> */}
                              <Dropdown.Item
                                onClick={() => openConfirmDelete(product)}
                              >
                                {product?.product_is_delete ? "Unarchive" : "Archive"}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* <div onClick={(() => confirmDelete(campaign))} className='cursor-pointer delete-icon'>
                                            <i className="fa-solid fa-trash f-14"></i>
                                            </div> */}
                        </div>
                      </div>

                      <div className="mt-2 campaign-title">
                        <a
                          className="text-light-blue"
                          href={`${
                            organizationInfo?.organization_short_name ? 
                            organizationInfo?.organization_short_name : 'masjid'
                          }/product/${product?.product_donation_page_link}`}
                          target={"_self"}
                        >
                          Preview Product Page {">"}{" "}
                        </a>
                      </div>

                      <div className="mt-3">
                        <div>
                          <span className="f-14">Amount Raised</span>
                        </div>
                        <div>
                          <a href={null} className="f-16">
                            {currencySign}{product?.total_amount ? product?.total_amount : 0}
                            <span className="text-light-blue mx-1">
                              ({product?.total_donations} donation)
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-xl-4 mt-2">
                          {/* <div className="form-group">
    
                                                <div className="input-group">
                                                    <span className="input-group-text text-body">
                                                        <i className="fa-solid fa-terminal"></i>
                                                    </span>
                                                    <input type="text" className="form-control" placeholder="Embed codes" />
                                                </div>
                                            </div> */}
                          <a
                            href={`/layout/email-receipt/${product?.product_id}`}
                            className="btn-action w-100 d-flex justify-content-start align-items-center cursor-pointer"
                          >
                            <span className="me-2">
                              <i className="fa-solid fa-envelope-open-text"></i>
                            </span>
                            <span>Receipt Emails</span>
                          </a>
                        </div>
                        <div className="col-xl-4 mt-2 ">
                          <a onClick={(e) => {
                          e.stopPropagation();
                          product?.product_id !== qrCode ? 
                          setQrCode(product?.product_id) : setQrCode('')
                        }} className="btn-action w-100 d-flex justify-content-start align-items-center cursor-pointer">
                            <span className="me-2">
                              <i className="fa-solid fa-qrcode"></i>
                            </span>
                            <span>QR Code</span>
                          </a>
                          <div className='position-relative'>
                          <LinkQRCode
                          url={`${window.location.origin}/${
                            organizationInfo?.organization_short_name ?
                            organizationInfo?.organization_short_name : 'masjid'
                          }/product/${product?.product_donation_page_link}`}
                          name={product?.product_campaign_title}
                          qrCode={qrCode}
                          id={product?.product_id}/>
                          </div>
                        </div>

                        <div className="col-xl-4 mt-2">
                          <Link
                            to={`/offline-donation/${product?.product_id}/${product.product_campaign_title}/product`}
                            className="btn-action w-100 d-flex justify-content-start align-items-center cursor-pointer"
                          >
                            <span className="me-2">
                              <i className="fa-solid fa-money-bill"></i>
                            </span>
                            <span>Add Offline Donation</span>
                          </Link>
                        </div>
                        {/* <div className='col-xl-4'>
                                            <div className="btn-action w-100 d-flex justify-content-start align-items-center cursor-pointer">
                                            <span className='me-2'><i className="fa-solid fa-hand-holding-heart"></i></span>
                                            <span>QR Code</span>
                                        </div>
                                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
              
              {
                dataLoader ? 
                <Loader /> : 
              !dataLoader && productList?.length === 0 ? (
                <div className="text-center mt-3">
                  {/* <img src="../../../img/masjid/no-data.png" alt='No Data'/> */}
                  <i
                    style={{ fontSize: "4rem" }}
                    className="fa-solid fa-list-ul"
                  ></i>
                  <p>
                    {searchString
                      ? "No products found against this search"
                      : "No products available!"}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* confirmation dialog start */}
        <ConfirmationDialog
          ref={confirmationDialogRef}
          executeAction={deleteProduct}
        />
        {/* confirmation dialog end */}

        {/* copy campaign dialog start */}
        <CopyCampaignDialog
          ref={copyCampaignDialogRef}
          callback={getAllProducts} />
        {/* copy campaign dialog end */}
      </>
    );
    //html side end
}

export default Products