import React, { useEffect, useState } from 'react';
import Sidebar from './Common/Sidebar/Sidebar';
import { Outlet } from "react-router-dom";
import MainNavbar from './Common/navbar/navbar';
import AnnouncementView from '../../shared/announcement/announcement-view';
import { getSetOrgInfo, getUserInfo } from '../../utils/user-info';
import { CURRENCY_CODE_SIGN_MAPPING } from '../../constants/currency-code-sign.constant';

function MainLayout(props) {
    const userInfo = getUserInfo();
    const organizationInfo = getSetOrgInfo();
    const [currencySign, setCurrencySign] = useState("");
    const [currentCurrency, setCurrentCurrency] = useState("");

    useEffect(() => {
        if(organizationInfo?.currency){
            setCurrencySign(CURRENCY_CODE_SIGN_MAPPING[organizationInfo?.currency]);
            setCurrentCurrency(organizationInfo?.currency);
        }
    }, [organizationInfo]);

    return (
        <div>
            {/* sidebar component start  */}
            <Sidebar />
            {/* sidebar component end  */}

            {/* main content start */}
            <main className="main-content position-relative border-radius-lg">

                {/*  announcement bar */}
               {userInfo?.role === 2 ? <AnnouncementView /> : ''} 
                {/* navbar start */}
                <MainNavbar />
                {/* navbar end */}

                {/* router start */}
                <div className='container-fluid mt-3 pb-5'>
                    <div className='bg-greyish px-3 py-4'>

                        {/* mein layout content start */}
                        <Outlet context={{currencySign, currentCurrency}} />
                        {/* mein layout content end */}

                    </div>

                </div>

                {/* router end */}

            </main>
            {/* main content end */}
        </div>
    );
}

export default MainLayout;