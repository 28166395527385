import { Outlet } from "react-router-dom";
import CampaignSidebar from "./Common/Campaign-Sidebar";
import { getSetOrgInfo } from "../../utils/user-info";
import { useState } from "react";
import { CURRENCY_CODE_SIGN_MAPPING } from "../../constants/currency-code-sign.constant";
import { useEffect } from "react";

function CampaignLayout(props) {

    const organizationInfo = getSetOrgInfo();
    const [currencySign, setCurrencySign] = useState("");

    useEffect(() => {
        if(organizationInfo?.currency){
            setCurrencySign(CURRENCY_CODE_SIGN_MAPPING[organizationInfo?.currency]);
        }
    }, [organizationInfo]);

    return (
        <div >
            {/* sidebar start */}
            {/* <CampaignSidebar /> */}
            {/* sidebar end */}

            {/* campaign main content start */}
            <Outlet context={{currencySign}} />
            {/* campaign main content end */}

        </div>
    );
}

export default CampaignLayout;